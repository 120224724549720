import React, { useContext } from "react";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form, { FormInput } from "../../common/forms/Form";
import { useUpdateDataTitle } from "../../../hooks";
import { MessagesContext } from "../../../contexts/MessagesContext";

// validation schema
const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
});

const EditTitleForm = ({ data, closePopup }) => {
  const { addMessage } = useContext(MessagesContext);
  const { handleFormSubmit } = useUpdateDataTitle(closePopup, data, addMessage);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
    },
  });

  const onSubmit = (data) => {
    console.log("send data", data);
    handleFormSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="title"
        defaultValue={""}
        render={({ field }) => (
          <FormInput
            label={`Please enter a new title for the ${data?.data_type}`}
            placeholder="Write new title here"
            error={errors.title}
            {...field}
          />
        )}
      />

      <div className="button-group flex flex-row justify-end gap-2 mt-2">
        <button
          onClick={closePopup}
          type="button"
          className="bg-transparent text-zinc-800 text-sm px-3 py-2 rounded-md border border-zinc-300 bg-zinc-50 hover:bg-zinc-100"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-zinc-900 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-900/90 transition ease-in-out 2s"
        >
          Save
        </button>
      </div>
    </Form>
  );
};

export default EditTitleForm;
