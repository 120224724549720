import React, { useState } from "react";
import { useGroups } from "../../../hooks/api/useGroups";
import { handleToggleClick } from "./handlers";
import DatasetList from "./DatasetList";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { EmptyListPrompt, NavItem, NavList } from ".";
import { useActiveProject } from "../../../hooks/useActiveProject";

const GroupList = ({
  activePath,
  onNodeClick,
  openEditDialog,
  setIsEditMode,
  setGroupData,
  ActionButtons,
}) => {
  const [openNodes, setOpenNodes] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const { projectId } = useActiveProject();
  const { data: groups, isLoading: isLoadingGroups } = useGroups(projectId);

  const sortedArray = groups?.sort((a, b) => b.id - a.id);

  const handleNodeClick = async (group) => {
    onNodeClick([projectId, group.id]);
  };

  const handleActionButtonClick = (group) => {
    onNodeClick([projectId, group.id], "create");
  };

  if (isLoadingGroups)
    return (
      <div className="p-2">
        <LoadingPlaceholder height="h-3" rows={3} />
      </div>
    );

  return (
    <NavList nestedClass={false}>
      {sortedArray?.length === 0 && (
        <EmptyListPrompt icon={"+"} text={"create a group"} />
      )}
      {sortedArray?.map((group) => (
        <NavItem
          key={group.id}
          activePathNumber={1}
          onMouseEnter={() => setHoveredNode(group.id)} // set the hovered node id
          onMouseLeave={() => setHoveredNode(null)} // clear it when the mouse leaves
          nodeItem={group}
          activePath={activePath}
          handleToggleClick={() => handleToggleClick(group.id, setOpenNodes)}
          handleNodeClick={() => handleNodeClick(group)}
          handleActionButtonClick={() => handleActionButtonClick(group)}
          hoveredNode={hoveredNode}
          openNodes={openNodes}
          openEditDialog={openEditDialog}
          setIsEditMode={setIsEditMode}
          setGroupData={setGroupData}
          ActionButtons={ActionButtons}
        >
          {openNodes.includes(group.id) && (
            <DatasetList
              groupId={group.id}
              groupColor={group.color}
              activePath={activePath}
              onNodeClick={onNodeClick}
              ActionButtons={ActionButtons}
              openEditDialog={openEditDialog}
            />
          )}
        </NavItem>
      ))}
    </NavList>
  );
};

export default GroupList;
