import React, { useState, useContext, useCallback } from "react";
import { EnvironmentSelect } from "../../common/EnvironmentSelect";
import Heading from "../../common/ui/Heading";
import useCreateProcessing from "../../../hooks/useCreateProcessing";
import useEnvironmentsData from "../../../hooks/useEnvironmentsData";
import { NodeDataContext } from "../../../contexts";
import NodeSelect from "../../common/NodeSelect";
import {
  fetchNodeDetails,
  useNodeDetailsQuery,
} from "../../../hooks/api/useNodeData";
import { useNavigate } from "react-router-dom";
import { useActiveProject } from "../../../hooks/useActiveProject";
import { FormCheck, FormInput } from "../../common/forms/Form";
import Tooltip from "../../common/Tooltip";
import Spinner from "../../common/ui/Spinner";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { NiceJsonForm } from "../NiceJsonForm";
import { uploadAllFiles } from "../NiceJsonForm/utils";

const formValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long")
    .max(150, "Title can not be more than 150 characters long."),
});

const ProcessingForm = () => {
  const nodeDataContext = useContext(NodeDataContext);
  const { setActiveNode, activeNode, nodeData } = nodeDataContext;
  const { projectTitle } = useActiveProject();
  const [importPath, setImportPathReal] = useState([]);
  const [importDone, setImportDone] = useState([]);
  const [isTemplate, setIsTemplate] = useState(false);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    environments,
    selectedEnvironment,
    envLoading,
    envError,
    environmentDetails,
    handleEnvironmentSelect,
  } = useEnvironmentsData();

  const setImportPath = useCallback(
    (path) => {
      if (path.length !== 4 || importPath.join() === path.join()) {
        return;
      }
      setImportPathReal(path);
      setImportDone(false);
    },
    [importPath]
  );

  const importData = useNodeDetailsQuery(importPath);

  const schema =
    environmentDetails &&
    environmentDetails.introspection &&
    environmentDetails.introspection.Processing
      ? environmentDetails.introspection.Processing
      : null;

  const [formSteps, setFormSteps] = useState([]);

  if (!importDone && importData.data) {
    setFormSteps(importData.data.steps);
    handleEnvironmentSelect(
      environments.filter((env) => env.id === importData.data.environment)[0]
    );
    console.log("IMPORT STEPS", importData.data.steps);
    setImportDone(true);
  }

  const handleTemplateChange = () => {
    setIsTemplate(!isTemplate); // Toggle the value of is_template
  };

  const onProcessingSuccess = (data) => {
    fetchNodeDetails(activeNode);
    navigate(`/project/${projectTitle}/studio/processing-inversion`);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      title: "",
    },
  });

  const { handleFormSubmit } = useCreateProcessing(
    activeNode[0],
    activeNode[1],
    activeNode[2],
    selectedEnvironment,
    title,
    isTemplate,
    importPath ? importPath[3] : undefined,
    setActiveNode,
    onProcessingSuccess,
    isLoading,
    setIsLoading
  );

  const onSubmit = async () => {
    setIsLoading(true);
    const newFormSteps = await uploadAllFiles(formSteps);
    handleFormSubmit(newFormSteps);
  };

  if (envError) return <div>Error loading environment details...</div>;

  console.log(schema);
  return (
    <div className="bg-zinc-100 py-10 px-16 w-full h-full overflow-scroll">
      <div className="bg-white shadow-lg shadow-zinc-300/40 py-5 px-6 m-4 rounded-lg w-6/6 max-w-8xl border border-zinc-100 flex flex-col gap-5">
        <div className="header flex justify-between items-center">
          <Heading size="h2">Process: {nodeData?.title}</Heading>
          <div className="flex items-center gap-2 text-sm">
            <Tooltip tooltipText={"Software version"} placement="left">
              {envLoading ? (
                <div>...</div>
              ) : (
                <EnvironmentSelect
                  environments={environments}
                  selectedEnvironment={selectedEnvironment}
                  onEnvironmentSelect={handleEnvironmentSelect}
                />
              )}
            </Tooltip>
          </div>
        </div>

        <div className="space-y-4 w-full">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <FormInput
                label="Title"
                placeholder="i.e Processing-1"
                size="base"
                error={errors.title}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setTitle(e.target.value);
                }}
              />
            )}
          />
        </div>

        <div>
          <div className="font-medium text-lg flex gap-2 items-center justify-between mb-2">
            <div className="flex gap-2 items-center">
              Processing parameters
              <NodeSelect
                projectId={activeNode[0]}
                activePath={importPath}
                setActivePath={setImportPath}
                nothingTitle="Import"
                IconComponent={ArrowUpTrayIcon}
              />
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
            <NiceJsonForm
              schema={schema}
              value={formSteps}
              setValue={setFormSteps}
            />

            <div className="flex flex-col space-y-8">
              <div className="flex items-center gap-2 text-zinc-800 text-sm px-0.5 rounded-md whitespace-nowrap mt-4">
                <FormCheck
                  type="checkbox"
                  id="is_template"
                  name="is_template"
                  checked={isTemplate}
                  isCheckBox={false}
                  onChange={handleTemplateChange}
                />
                Make processing template
              </div>
              <button
                type="button"
                disabled={formSteps.length === 0}
                className={`bg-zinc-700 hover:bg-zinc-600 text-white py-2 px-10 rounded-md w-full flex items-center justify-center ${
                  formSteps.length === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-zinc-600"
                }`}
                onClick={handleSubmit(onSubmit)}
              >
                {isLoading ? (
                  <Spinner
                    borderColor="rgba(255, 255, 255, 0.5)"
                    borderTopColor="white"
                  />
                ) : (
                  "Process"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProcessingForm;
