import { useContext, useEffect, useRef, useState } from "react";
import { ExclamationTriangleIcon } from "../../../../assets/icons";
import Heading from "../../../common/ui/Heading";
import Spinner from "../../../common/ui/Spinner";
import { NodeDataContext } from "../../../../contexts";

const NodeLogs = ({ yamlifyLogs, nodeData }) => {
  const logsRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const lines = yamlifyLogs.split("\n");
  const { nodeType } = useContext(NodeDataContext);

  useEffect(() => {
    if (logsRef.current) {
      logsRef.current.scrollTop = logsRef.current.scrollHeight;
    }
  }, [yamlifyLogs]);

  return (
    <div className="bg-white flex flex-col gap-1 rounded-md py-4 px-6 pb-8 shadow-sm shadow-zinc-200 w-full relative mb-5">
      <div className="flex items-center justify-between">
        <Heading size="h5">{"Logs"}</Heading>
        <div className="mb-2">
          {!nodeData?.status || nodeData?.status === "running" ? (
            <div className="flex items-center gap-1.5">
              <Spinner />
            </div>
          ) : nodeData?.status === "failed" ? (
            <div className="flex items-center gap-1 text-red-500">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-500" />
              <span className="capitalize">
                {nodeType ? nodeType : "Logs fetching"}
              </span>
              failed
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`logs-container ${
          isExpanded ? "expanded" : ""
        } flex gap-1 bg-zinc-50 subpanel overflow-scroll rounded-md`}
        ref={logsRef}
      >
        <div className="line-numbers w-4 p-3 text-sm text-zinc-400 pb-5">
          {lines.map((_, index) => (
            <div key={index} className="line-number">
              {index + 1}
            </div>
          ))}
        </div>
        <pre className="logs-container--content text-sm text-zinc-600 p-3 pb-5">
          {yamlifyLogs}
        </pre>
      </div>

      {/* Overlay */}
      {!isExpanded && (
        <div className="overlay absolute bottom-0 w-full -ml-6 mb-4 rounded-md p-3 flex justify-center">
          <button
            className="view-toggle-btn text-zinc-800 hover:text-white hover:bg-zinc-600/75 p-1 rounded-md text-sm"
            onClick={() => setIsExpanded(true)}
          >
            View more
          </button>
        </div>
      )}
      {isExpanded && (
        <div className="overlay absolute bottom-0 w-full -ml-6 mb-4 rounded-md p-3 flex justify-center">
          <button
            className="view-toggle-btn text-zinc-800 hover:text-white hover:bg-zinc-600/75 p-1 rounded-md text-sm"
            onClick={() => setIsExpanded(false)}
          >
            View less
          </button>
        </div>
      )}
    </div>
  );
};

export default NodeLogs;
