export const hexToRgba = (hex, alpha = 1) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${alpha})`
    : null;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const darkenColor = (color, percent) => {
  // Ensure the percent is between 0 and 100
  const validPercent = Math.max(0, Math.min(100, percent));

  // Parse the color string to RGB
  let [r, g, b] = color.match(/\d+/g).map(Number);

  // Calculate the adjustment value
  const adjust = (amount, darkeningAmount) =>
    Math.round(amount * (1 - darkeningAmount / 100));

  // Adjust and return the color
  r = adjust(r, validPercent);
  g = adjust(g, validPercent);
  b = adjust(b, validPercent);

  return `rgb(${r}, ${g}, ${b})`;
};

export const colorOptions = [
  "#c290f1", // Light Purple
  "#ff5733", // Red
  "#40cee5", // Turquoise
  "#aed648", // Light green
  "#1ab787", // Emerald
  "#ff9933", // Orange
  "#535357", // Stone
];
