import Uuid from "./utils/uuid"; // eslint-disable-line
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import PaymentPage from "./pages/PaymentPage";
import ErrorPage from "./components/common/ErrorPage";
import BillingPage from "./pages/BillingPage";

import { Messages } from "./components/common/Messages";
import { MessagesProvider } from "./contexts/MessagesContext";
import { WorkspaceProvider } from "./contexts/WorkspaceContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { NodeDataProvider } from "./contexts/NodeDataContext";
import { NodeInfoProvider } from "./contexts/NodeInfoContext";

import { useQuery } from "@tanstack/react-query";
import { InteractiveStateProvider } from "./contexts/InteractiveStateContext";
import ProjectWorkspace from "./pages/ProjectWorkspace";
import { NodeStatusProvider } from "./contexts/NodeStatusContext";
import { DialogProvider } from "./contexts/DialogContext";
import EnvironmentPage from "./pages/EnvironmentPage";
import NiceJsonEditorPage from "./pages/NiceJsonEditorPage";

const ComponentPage = () => {
  const fullPath = window.location.pathname.slice("/views/".length);
  const path = fullPath.slice(0, fullPath.lastIndexOf("/"));
  const componentName = fullPath.slice(fullPath.lastIndexOf("/") + 1);

  const componentQuery = useQuery({
    queryKey: [path],
    queryFn: () => {
      return import(`./${path}`);
    },
    persister: null,
  });

  if (componentQuery.data) {
    const Component = componentQuery.data[componentName];
    return <Component />;
  } else if (componentQuery.isError) {
    return (
      <div>Error loading component: {JSON.stringify(componentQuery.error)}</div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const App = () => {
  if (window.location.pathname.startsWith("/accounts")) return null;

  return (
    <MessagesProvider>
      <NodeStatusProvider>
        <DialogProvider>
          <SidebarProvider>
            <InteractiveStateProvider>
              <NodeDataProvider currentPath={window.location.pathname}>
                <WorkspaceProvider>
                  <NodeInfoProvider>
                    <div
                      className="app"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                      }}
                    >
                      <Messages />
                      <Router>
                        <Routes>
                          <Route exact path="/" element={<HomePage />} />
                          <Route
                            path="/project/:projectSlug/*"
                            element={<ProjectWorkspace />}
                          />
                          <Route
                            path="/environments"
                            element={<EnvironmentPage />}
                          />

                          <Route
                            path="/nicejsoneditor"
                            element={<NiceJsonEditorPage />}
                          />

                          <Route
                            path="/payment/return"
                            element={<PaymentPage />}
                          />
                          <Route
                            path="/project/billing"
                            element={<BillingPage />}
                          />
                          <Route path="/profile" element={<ProfilePage />} />
                          <Route path="/views/*" element={<ComponentPage />} />
                          <Route
                            path="*"
                            element={
                              <ErrorPage message={"404 Page not found"} />
                            }
                          />
                          <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                          />
                        </Routes>
                      </Router>
                    </div>
                  </NodeInfoProvider>
                </WorkspaceProvider>
              </NodeDataProvider>
            </InteractiveStateProvider>
          </SidebarProvider>
        </DialogProvider>
      </NodeStatusProvider>
    </MessagesProvider>
  );
};

export default App;
