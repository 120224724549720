import React, { useContext, useState } from "react";
import { ActionProcessingIcon, ProcessingIcon } from "../../../assets/icons";
import { useProcessings } from "../../../hooks/api/useProcessings";
import { handleToggleClick } from "./handlers";
import InversionList from "./InversionList";
import useInvalidateQueryOnStatus from "../../../hooks/useInvalidateQueryOnStatus";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { EmptyListPrompt, NavItem, NavList } from ".";
import { darkenColor, hexToRgba } from "../../../utils";
import { useActiveProject } from "../../../hooks/useActiveProject";
import { NodeDataContext } from "../../../contexts";

const ProcessingList = ({
  groupId,
  datasetId,
  groupColor,
  onNodeClick,
  activePath,
  ActionButtons,
  openEditDialog,
}) => {
  const [openNodes, setOpenNodes] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const { projectId } = useActiveProject();
  const { activeNode, setActiveNode } = useContext(NodeDataContext);
  const { data: processings, isLoading: isLoadingProcessings } = useProcessings(
    projectId,
    groupId,
    datasetId
  );

  const sortedArray = processings?.sort((a, b) => b.id - a.id);

  useInvalidateQueryOnStatus("processings", "processing");

  const handleNodeClick = async (id) => {
    onNodeClick([projectId, groupId, datasetId, id]);
  };

  const handleActionButtonClick = async (id) => {
    onNodeClick([projectId, groupId, datasetId, id], "create");
  };

  const handleOneClickProcInvClick = (id) => {
    console.log("BEFORE", activeNode);
    setActiveNode([projectId, groupId, datasetId, id]);
    console.log("AFTER", activeNode);
  };

  if (isLoadingProcessings || !processings)
    return (
      <div className="p-2">
        <LoadingPlaceholder height="h-3" rows={1} />
      </div>
    );

  return (
    <NavList>
      {sortedArray.length === 0 && (
        <EmptyListPrompt
          icon={<ActionProcessingIcon />}
          text={"to process data"}
        />
      )}
      {sortedArray?.map((processing) => (
        <NavItem
          key={processing.id}
          activePathNumber={3}
          onMouseEnter={() => setHoveredNode(processing.id)} // set the hovered node ID
          onMouseLeave={() => setHoveredNode(null)} // clear it when the mouse leaves
          nodeItem={processing}
          activePath={activePath}
          handleToggleClick={() =>
            handleToggleClick(processing.id, setOpenNodes)
          }
          handleNodeClick={() => handleNodeClick(processing.id)}
          handleActionButtonClick={() => handleActionButtonClick(processing.id)}
          hoveredNode={hoveredNode}
          openNodes={openNodes}
          groupColor={groupColor}
          DoneStatusIcon={ProcessingIcon}
          iconColorProp={darkenColor(hexToRgba(groupColor), 0)}
          handleOneClickProcInvClick={() =>
            handleOneClickProcInvClick(processing.id)
          }
          ActionButtons={ActionButtons}
          openEditDialog={openEditDialog}
        >
          {openNodes.includes(processing.id) && (
            <InversionList
              groupId={groupId}
              groupColor={groupColor}
              datasetId={datasetId}
              processingId={processing.id}
              activePath={activePath}
              onNodeClick={onNodeClick}
              ActionButtons={ActionButtons}
              openEditDialog={openEditDialog}
            />
          )}
        </NavItem>
      ))}
    </NavList>
  );
};

export default ProcessingList;
