import React, { useContext, useEffect, useState, useRef } from "react";
import { NodeDataContext } from "../../../../contexts";
import ProgressPlot from "./ProgressPlot";
import NodeLogs from "./NodeLogs";
import Toolbar from "../Plotting/Toolbar";
import { useNodeProgress } from "../../../../hooks/api/useNodeProgress";

const NodeStatus = () => {
  const containerRef = useRef(null);
  const { activeNode, nodeData } = useContext(NodeDataContext);
  const { yamlifyLogs } = useNodeProgress(activeNode);
  const [plotWidth, setPlotWidth] = useState(1000);

  useEffect(() => {
    // Check if ResizeObserver is supported
    const currentContainer = containerRef.current;
    if (typeof ResizeObserver !== "undefined" && currentContainer) {
      const totalPadding = 2;

      // Create a new ResizeObserver instance
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setPlotWidth(entry.contentRect.width - totalPadding);
        }
      });
      resizeObserver.observe(currentContainer);

      return () => {
        if (currentContainer) {
          resizeObserver.unobserve(currentContainer);
        }
      };
    }
  });

  const showProgressPlot = activeNode.length === 5;

  return (
    <div className="h-screen overflow-hidden ">
      <Toolbar progressView={true} />
      <div className="p-3 pb-4 flex flex-col gap-3 w-full h-full bg-zinc-100  overflow-y-scroll mb-5">
        {showProgressPlot && (
          <ProgressPlot
            containerRef={containerRef}
            nodeData={nodeData}
            activeNode={activeNode}
            plotWidth={plotWidth}
          />
        )}
        <NodeLogs
          yamlifyLogs={yamlifyLogs}
          nodeData={nodeData}
          activeNode={activeNode}
        />
      </div>
    </div>
  );
};

export default NodeStatus;
