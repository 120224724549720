import React, { useEffect, useState } from "react";
import { useSidebarContext } from "../../../../contexts/SidebarContext";
import EditableElementPlotWrapper from "./EditableElementPlotWrapper";
import Toolbar from "./Toolbar";
import { ErrorHandler } from "../../../common/ErrorHandler";
import MetadataBar from "../../MetadataBar";
import { CustomAlert } from "../../../common/CustomAlert";
import useCustomAlert from "../../../../hooks/useCustomAlert";
import { useSubplotZooms } from "../../../../hooks/useSubplotZooms";
import { PlotContextProvider } from "./PlotContext";

const Plotting = () => {
  const { sidebarResized } = useSidebarContext();

  const [alertVisible, showAlert] = useCustomAlert();
  const [subplotZooms, setSubplotZooms] = useSubplotZooms();
  const [currentDragMode, setCurrentDragMode] = useState("zoom");

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize")); // Trigger resize event
    }, 300); // Delay
  }, [sidebarResized]);

  return (
    <PlotContextProvider>
      <div className="plot-container--outer bg-zinc-200/80">
        <CustomAlert message="Copied to clipboard" isVisible={alertVisible} />
        <Toolbar
          subplotZooms={subplotZooms}
          setSubplotZooms={setSubplotZooms}
          currentDragMode={currentDragMode}
          setCurrentDragMode={setCurrentDragMode}
        />
        <div className="plot-container--wrapper p-2">
          <ErrorHandler>
            <EditableElementPlotWrapper
              subplotZooms={subplotZooms}
              setSubplotZooms={setSubplotZooms}
              currentDragMode={currentDragMode}
              setCurrentDragMode={setCurrentDragMode}
            />
          </ErrorHandler>
        </div>
        <MetadataBar containerPosition="" onCopySuccess={showAlert} />
      </div>
    </PlotContextProvider>
  );
};

export default Plotting;
