//import { colorDefaultzinc, colorPalette } from "./colors";
import { colorDefaultzinc, colorPalette } from "./colors";
import {
  getGateTimes,
  markerSize,
  titleDatasetChannel,
  schemaDatasetChannelStyle,
  channel2col,
  channel2inusecol,
  channel2stdcol,
  channel2system,
} from "./utils";

const generic_sounding_gates = (unitDipole) => {
  return {
    title: titleDatasetChannel,
    xaxis: "gatetime",
    yaxis: unitDipole ? "dbdt" : "dbdtmoment",
    fn: function (context, args, elements) {
      const {
        nodeBinaryByLine,
        manualEdits,
        selectedLineId,
        selectedSoundingId,
      } = context;
      const binary =
        nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary || !binary.flightlines) return [];
      const channel = channel2col(args.channel);
      const sounding = selectedSoundingId;
      const channelnr = parseInt(
        channel.replace(/[^0-9]*([0-9]*)[^0-9]*/, "$1")
      );
      const gatetimes = getGateTimes(binary, "Channel" + channelnr.toString());
      const traces = [];
      const inUseChannel = channel2inusecol(args.channel);
      const stdChannel = channel2stdcol(args.channel);
      const systemChannel = channel2system(args.channel);
      const moment = unitDipole
        ? 1
        : binary.system[systemChannel].ApproxDipoleMoment;
      const dbdts = [];
      const stds = binary.layer_data[stdChannel] !== undefined ? [] : null;
      const inUse = [];

      const editBinary = manualEdits?.[selectedLineId];
      const applyIdx = editBinary?.flightlines?.apply_idx;
      const editIdx = applyIdx ? applyIdx.indexOf(sounding) : -1;

      for (var col in binary.layer_data[channel]) {
        dbdts.push(binary.layer_data[channel][col][sounding]);

        if (stds !== null) {
          stds.push(Math.abs(binary.layer_data[stdChannel][col][sounding]));
        }

        let inUseValue = 1;
        if (binary.layer_data[inUseChannel] !== undefined) {
          inUseValue = Number(binary.layer_data[inUseChannel][col][sounding]);
        }

        if (editIdx !== -1) {
          const editInUseValue =
            editBinary?.layer_data?.[inUseChannel]?.[col][editIdx];
          if (!isNaN(editInUseValue)) {
            inUseValue = editInUseValue;
          }
        }

        inUse.push(inUseValue);
      }

      var xdist = binary.flightlines.xdist[sounding];

      const name = args.channel + " @ " + xdist?.toFixed(0) + "m";

      const ys = dbdts.map((y) => Math.abs(y) * moment * 1e-12);
      const ysOriginal = dbdts.map((y) => y * moment * 1e-12);

      const color_idxs = args.showNegative
        ? ysOriginal.map((p, idx) => (p >= 0 ? 1 : 0) + 2 * inUse[idx])
        : ysOriginal.map((p, idx) => 2 * inUse[idx]);

      const lineWidth = 1;
      const style =
        args.style === "lines"
          ? "lines+markers"
          : args.style || "lines+markers";

      elements.yaxis[this.yaxis].exponentformat = "power";
      elements.xaxis[this.xaxis].exponentformat = "power";
      elements.yaxis[this.yaxis].tickfont = {
        size: 10,
      };
      elements.xaxis[this.xaxis].tickfont = {
        size: 10,
      };

      traces.push.apply(
        traces,
        [0, 1, 2, 3].map((color_idx) => {
          const yValues = ys.map((d, idx) =>
            (color_idxs[idx] === color_idx &&
              (color_idxs[idx - 1] >= color_idx ||
                color_idxs[idx + 1] >= color_idx)) ||
            (color_idxs[idx] > color_idx &&
              (color_idxs[idx - 1] === color_idx ||
                color_idxs[idx + 1] === color_idx))
              ? d
              : NaN
          );

          const errorArray = args.showError
            ? ys.map((d, idx) =>
                inUse[idx] === 1 &&
                (inUse[idx - 1] === 1 || inUse[idx + 1] === 1)
                  ? d * (1 + stds[idx]) - d
                  : NaN
              )
            : null;

          const errorArrayMinus = args.showError
            ? ys.map((d, idx) =>
                inUse[idx] === 1 &&
                (inUse[idx - 1] === 1 || inUse[idx + 1] === 1)
                  ? d - d / (1 + stds[idx])
                  : NaN
              )
            : null;

          const errorAmplitudes = stds.map((error) => (error * 100).toFixed(2));

          const customData = errorAmplitudes.map((amplitude, idx) => {
            return {
              error_amplitude: amplitude,
              gate: String(idx).padStart(2, "0"),
              yValue: ysOriginal[idx],
            };
          });

          const xUnit = elements.xaxis[this.xaxis].shortTitle;
          const yUnit = elements.yaxis[this.yaxis].shortTitle;

          const hoverTemplate = `${xdist?.toFixed(
            1
          )} m, %{customdata.yValue:.5e} ± %{customdata.error_amplitude}% ${yUnit} @ %{x} ${xUnit} [Gate%{customdata.gate}]<extra>Ch${String(
            channelnr
          ).padStart(2, "0")} ${args.dataset}</extra>`;

          let trace = {
            type: "scattergl",
            mode: style,
            marker: {
              size: args.style === "lines" ? 0.000001 : markerSize,
            },
            legendgroup: args.channel,
            name: name,
            showlegend: false,
            x: gatetimes,
            y: yValues,
            gate: col,
            error_y:
              args.showError && stds !== null
                ? {
                    type: "data",
                    symmetric: false,
                    array: errorArray,
                    arrayminus: errorArrayMinus,
                    width: 1,
                    thickness: 1,
                  }
                : null,
            hovertemplate: hoverTemplate,
            customdata: customData,
          };

          return trace;
        })
      );

      const applyColors = (traces) => {
        let colorIdx2Counter = 0; // Counter for dynamic color assignment for color_idx 2
        return traces.map((trace, index) => {
          let selectedColor; // Color to be determined based on conditions

          // Directly use dynamic color for the general case
          let dynamicColor =
            colorPalette[colorIdx2Counter % colorPalette.length];

          if (args.showNegative) {
            // Define specific colors for when args.showNegative is true
            const conditionColor = [
              "rgb(255, 156, 156)", // Light Red
              "rgb(156, 156, 255)", // Light Blue
              "rgb(255, 0, 0)", // Pure Red
              "rgb(0, 0, 255)", // Pure Blue
            ];

            selectedColor = conditionColor[index % conditionColor.length];
          } else {
            // For color_idx 2 when args.showNegative is false, assign dynamically
            if (index % 4 === 2) {
              selectedColor = dynamicColor; // Use the dynamically selected color
              colorIdx2Counter++; // Increment counter for the next dynamic color
            } else {
              // Default/fallback color for other cases
              selectedColor = colorDefaultzinc; // zinc
            }
          }

          // Apply the selected color with a consistent lineWidth for all traces
          trace.line = {
            color: selectedColor,
            width: lineWidth,
          };

          return trace;
        });
      };

      applyColors(traces);

      return traces;
    },

    schema: (context) => {
      const res = schemaDatasetChannelStyle(context);
      res.required.push("showError");
      res.properties.showError = {
        type: "boolean",
        default: false,
      };
      res.properties.showNegative = {
        type: "boolean",
        default: false,
      };
      return res;
    },
  };
};

export const sounding_gates = generic_sounding_gates(true);
export const sounding_gates_dipole_moment = generic_sounding_gates(false);
