import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormInput, FormLabel } from "../../common/forms/Form";
import { fetchTemplates } from "../../../api/fetchTemplates";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SimpleSelect from "../../common/SimpleSelect";
import client from "../../../api/client";
import Spinner from "../../common/ui/Spinner";
import { useQueryClient } from "@tanstack/react-query";
import { MessagesContext } from "../../../contexts/MessagesContext";
import { error2message } from "../../../hooks/api/useSafeQuery";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
});

const AutoProcessForm = ({ closeDialog, projectId, activeNode }) => {
  const [processingTemplates, setProcessingTemplates] = useState([]);
  const [inversionTemplates, setInversionTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let defaultTitle = "auto-proc-inv";
  const queryClient = useQueryClient();
  const { addMessage } = useContext(MessagesContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterAndSortData = (data) => {
          const filteredData = data.filter(
            (item) => item.status !== "failed" && item.show_in_ui !== "False"
          );
          filteredData.sort((a, b) => {
            if (b.is_global_template !== a.is_global_template) {
              return b.is_global_template - a.is_global_template;
            } else {
              return new Date(b.date) - new Date(a.date);
            }
          });
          return filteredData;
        };

        // Fetch both sets of data concurrently
        const [processingData, inversionData] = await Promise.all([
          fetchTemplates({ projectId: projectId, type: "processing" }),
          fetchTemplates({ projectId: projectId, type: "inversion" }),
        ]);

        // Filter and sort the data
        const filteredProcessingData = filterAndSortData(processingData);
        const filteredInversionData = filterAndSortData(inversionData);

        // Set state
        setProcessingTemplates(filteredProcessingData);
        setInversionTemplates(filteredInversionData);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchData();
  }, [projectId]);

  const [selectedProcessingTemplate, setSelectedProcessingTemplate] =
    useState();
  const [selectedInversionTemplate, setSelectedInversionTemplate] = useState();

  useEffect(() => {
    // Set the first entry with is_global_template to be default for processing template
    const defaultProcessingTemplate = processingTemplates.find(
      (template) => template.is_global_template
    );
    setSelectedProcessingTemplate(defaultProcessingTemplate || null);

    // Set the first entry with is_global_template to be default for inversion template
    const defaultInversionTemplate = inversionTemplates.find(
      (template) => template.is_global_template
    );
    setSelectedInversionTemplate(defaultInversionTemplate || null);
  }, [processingTemplates, inversionTemplates]);

  const handleProcessingTempSelect = (item) => {
    setSelectedProcessingTemplate(item);
  };

  const handleInversionTempSelect = (item) => {
    setSelectedInversionTemplate(item);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: defaultTitle,
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formDataObject = {
      title: data.title,
      processing_template: selectedProcessingTemplate?.id,
      inversion_template: selectedInversionTemplate?.id,
    };

    try {
      const processingResponse = await client.post(
        `/api/projects/${projectId}/groups/${activeNode[1]}/imports/${activeNode[2]}/processings/new`,
        {
          title: formDataObject.title,
          parent: formDataObject.processing_template,
        }
      );

      console.log("Form Data:", formDataObject);
      console.log("First Server Response:", processingResponse.data);

      const newProcessingId = processingResponse.data.processing.id;

      if (newProcessingId) {
        const inversionResponse = await client.post(
          `/api/projects/${projectId}/groups/${activeNode[1]}/imports/${activeNode[2]}/processings/${newProcessingId}/inversions/new`,
          {
            title: formDataObject.title,
            parent: formDataObject.inversion_template,
          }
        );
        console.log("Second Server Response:", inversionResponse.data);
      }

      console.log("Form submission successful!");
      closeDialog();
      setIsLoading(false);
      queryClient.invalidateQueries("processings");
    } catch (error) {
      setIsLoading(false);
      closeDialog();
      if (error.response?.status === 401) {
        const errorMessage = {
          title: error.response.statusText,
          message:
            "You are not allowed to process or invert data in the DEMO project.",
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        console.error("Error:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <Controller
          control={control}
          name="title"
          defaultValue={defaultTitle || ""}
          render={({ field }) => (
            <FormInput
              label={"Title"}
              placeholder="Give the processing and inversion a name"
              error={errors.title}
              {...field}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <FormLabel
            label={"Processing templates"}
            htmlFor={"processing-settings"}
          />
          <button className="text-zinc-600 text-sm px-1 hidden">Edit</button>
        </div>

        <SimpleSelect
          items={processingTemplates}
          selected={selectedProcessingTemplate}
          setSelected={handleProcessingTempSelect}
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
          width=""
          showCheckIcon={true}
        />
        <input
          type="hidden"
          name="processing_template"
          value={
            selectedProcessingTemplate ? selectedProcessingTemplate.id : ""
          }
        />
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <FormLabel
            label={"Inversion templates"}
            htmlFor={"inversion-settings"}
          />
          <button className="text-zinc-600 text-sm px-1 hidden">Edit</button>
        </div>
        <SimpleSelect
          items={inversionTemplates}
          selected={selectedInversionTemplate}
          setSelected={handleInversionTempSelect}
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
          width=""
          showCheckIcon={true}
        />
        <input
          type="hidden"
          name="inversion_template"
          value={selectedInversionTemplate ? selectedInversionTemplate.id : ""}
        />
      </div>

      <div className="button-group flex flex-row justify-between gap-2 mt-2">
        <button
          onClick={closeDialog}
          type="button"
          className="bg-transparent text-zinc-600 px-4 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
        >
          Cancel
        </button>

        <button
          type="submit"
          className="bg-zinc-800 text-white  px-4 py-2 rounded-md hover:bg-zinc-700"
        >
          {isLoading ? (
            <div className="flex items-center">
              <Spinner
                borderColor="rgba(255, 255, 255, 0.5)"
                borderTopColor="white"
              />
              Process and invert
            </div>
          ) : (
            "Process and invert"
          )}
        </button>
      </div>
    </form>
  );
};

export default AutoProcessForm;
