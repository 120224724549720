import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { MessagesContext } from "../../contexts/MessagesContext"; // eslint-disable-line

export const error2message = (error) => {
  if (error.response) {
    const url = "data:text/html;base64," + btoa(unescape(encodeURIComponent(error.response.data)));
    return {
      type: "error",
      title: error.message,
      message: <iframe src={url} style={{ width: "100%", height: "400px" }} title="Django traceback" />,
    };
  } else if (error.message) {
    return {
      type: "error",
      title: error.message,
      message: (
        <div>
          <h1>{error.message}</h1>
          <pre>{error.stack}</pre>
        </div>
      ),
    };
  } else {
    return {
      type: "error",
      title: "Oops, an error occured ...",
      message: JSON.stringify(error),
    };
  }
};

export const useSafeQuery = (dependencies, fn) => {
  const { addMessage } = useContext(MessagesContext);

  // FIXME: This is just backwards compatibility
  let query = dependencies;
  if (fn !== undefined) {
    query = {
      queryKey: dependencies,
      queryFn: fn,
    };
  }

  const queryFnOrig = query.queryFn;
  query = {
    ...query,
    queryFn: async () => {
      try {
        return await queryFnOrig();
      } catch (error) {
        addMessage(error2message(error));
        return null;
      }
    },
  };

  const res = useQuery(query);
  /*if (res.isStale) {
    console.log("STALE", dependencies, res);
  }*/
  return res;
};

export default useSafeQuery;
