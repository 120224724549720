import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export const ProgressLoader = ({ loadingProgress }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div
      className="absolute right-2 bottom-12 rounded-md bg-zinc-800 shaodw-md z-50 w-60 p-2.5 text-xs text-zinc-300 flex flex-col gap-1.5"
      style={{ zIndex: 100 }}
    >
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${loadingProgress}%` }}></div>
      </div>
      <div className="w-full flex gap-3 justify-between text-zinc-300 items-center">
        <div className="flex items-center gap-1.5">
          Fetching flightline data
          <button
            type="button"
            onClick={toggleVisibility}
            className="text-zinc-300 hover:text-zinc-50 transition ease-in-out hover:bg-zinc-700/80 rounded-md"
          >
            {isVisible ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
          </button>
        </div>
        <div className="w-12 text-right font-semibold">
          {loadingProgress.fetched}/{loadingProgress.total}
        </div>
      </div>
      {loadingProgress.remains.length > 0 && (
        <div
          className={`w-full flex justify-center flex-col text-zinc-400 transition-all duration-300 overflow-hidden ${
            isVisible ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <ul className="pb-1">
            {loadingProgress.remains.map((areaId, index) => (
              <li key={areaId} className={index === 0 ? "text-blue-500" : ""}>
                {index === 0 ? `> Fetching area ${areaId}` : `... ${areaId}`}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
