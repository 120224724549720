import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { error2message } from "./api/useSafeQuery";

const useUpdateDataTitle = (onClose, data, addMessage) => {
  const queryClient = useQueryClient();

  const projectId = data?.project_id;
  const groupId = data?.group_id;
  const importId =
    data.data_type === "processing" || data.data_type === "inversion"
      ? data?.file_import
      : data.id;
  const processingId =
    data.data_type === "processing"
      ? data?.id
      : data.processing_id || data.processing;
  const inversionId = data.data_type === "inversion" ? data?.id : null;

  const dataTypeToInvalidate = (() => {
    if (data.data_type === "processing") {
      return "processings";
    } else if (data.data_type === "inversion") {
      return "inversions";
    } else {
      return "datasets";
    }
  })();

  const path = (() => {
    if (data.data_type === "import") {
      return `/api/projects/${projectId}/groups/${groupId}/imports/${importId}/update-title/`;
    } else if (data.data_type === "processing") {
      return `/api/projects/${projectId}/groups/${groupId}/imports/${importId}/processings/${processingId}/update-title/`;
    } else if (data.data_type === "inversion") {
      return `/api/projects/${projectId}/groups/${groupId}/imports/${importId}/processings/${processingId}/inversions/${inversionId}/update-title/`;
    } else {
      throw new Error(`Unsupported data type: ${data.data_type}`);
    }
  })();

  const updateTitleMutation = useMutation({
    mutationFn: (formData) => client.put(path, { title: formData.title }),
    onSuccess: () => {
      queryClient.invalidateQueries(dataTypeToInvalidate);
      if (typeof onClose === "function") {
        onClose();
      }
    },
    onError: (error) => {
      onClose();
      if (error.response.status === 401) {
        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };
        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (formData) => {
    updateTitleMutation.mutate(formData);
  };

  return {
    updateTitleMutation,
    handleFormSubmit,
  };
};

export default useUpdateDataTitle;
