import React, { useState, useEffect, useRef } from "react";

class EventBus {
  constructor() {
    this.listeners = {};
  }

  on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  off(event, callback) {
    if (!this.listeners[event]) {
      return;
    }
    const index = this.listeners[event].indexOf(callback);
    if (index > -1) {
      this.listeners[event].splice(index, 1);
    }
  }

  emit(event, data) {
    if (!this.listeners[event]) {
      return;
    }
    this.listeners[event].forEach((callback) => callback(data));
  }
}

export const eventBus = new EventBus();

const ContextMenu = ({ menuItems, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef();

  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent triggering context menus higher in the hierarchy
    eventBus.emit("close-context-menu"); // Close all other context menus
    setPosition({ x: event.pageX, y: event.pageY });
    setIsVisible(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the menuRef element
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    // Close the menu when another context menu is opened
    const closeMenu = () => setIsVisible(false);
    eventBus.on("close-context-menu", closeMenu);

    if (isVisible) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("contextmenu", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("contextmenu", handleOutsideClick);
      eventBus.off("close-context-menu", closeMenu);
    };
  }, [isVisible]);

  return (
    <>
      <div onContextMenu={handleContextMenu}>{children}</div>
      {isVisible && (
        <ul
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
          className="fixed bg-zinc-800/70 backdrop-blur-md outline outline-1 outline-zinc-800/60 text-white p-0.5 rounded-md shadow max-w-48"
          style={{
            top: `${position.y}px`,
            left: `${position.x}px`,
            zIndex: 1000,
          }}
        >
          {menuItems.map((item, index) => (
            <li
              key={index}
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent menu from closing on click
                item.onClick();
                setIsVisible(false); // Optionally close the menu after action
              }}
            >
              <button className="px-2.5 py-2 hover:bg-zinc-800 rounded-md w-full text-left text-xs flex gap-2 min-w-28">
                {item.icon && <item.icon className="w-4 h-4" />}
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ContextMenu;
