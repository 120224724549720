import React, { useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { SideNav } from "../components/workspace/SideNav";
import MiscData from "../components/workspace/modules/MiscData";
import ProjectDashboard from "../components/workspace/modules/ProjectDashboard";
import PlotOrProgressContainer from "../components/workspace/PlotOrProgressContainer";
import { Sidebar } from "../components/workspace/Sidebar";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { useSidebarContext } from "../contexts/SidebarContext";
import { ImportForm } from "../components/workspace/forms/ImportForm";
import ProcessingForm from "../components/workspace/forms/ProcessingForm";
import InversionForm from "../components/workspace/forms/InversionForm";
import { Map } from "../components/workspace/modules/Map";
import { NodeStatus } from "../components/workspace/modules/NodeStatus";

const ProjectWorkspace = () => {
  const location = useLocation();

  const [sizes, setSizes] = useState([100, "30%", "auto"]);
  const { toggleSidebarResized } = useSidebarContext();

  // List of paths that should have a sidebar
  const pathsToShowSidebar = [
    "/studio/processing-inversion",
    "/studio/processing-inversion/new-import",
    "/studio/processing-inversion/new-processing",
    "/studio/processing-inversion/new-inversion",
    "/studio/processing-inversion/map",
    "/studio/processing-inversion/inversion-progress",
  ];

  const showSidebar = pathsToShowSidebar.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <div className="beryl_project_workspace gradient-background">
      <SideNav />
      {showSidebar ? (
        <SplitPane
          split="vertical"
          sizes={sizes}
          onChange={setSizes}
          resizerSize={8}
          onDragEnd={toggleSidebarResized}
          className="overflow-visible"
        >
          <Pane
            minSize={1}
            maxSize="40%"
            style={{
              backgroundColor: "white",
              borderTopLeftRadius: "2rem",
              borderBottomLeftRadius: "2rem",
            }}
          >
            <Sidebar setSizes={setSizes} />
          </Pane>

          <Routes>
            <Route index path="dashboard" element={<ProjectDashboard />} />
            <Route path="dashboard" element={<ProjectDashboard />} />
            <Route
              path="/studio/processing-inversion"
              element={<PlotOrProgressContainer />}
            />
            <Route
              path="/studio/processing-inversion/new-import"
              element={<ImportForm />}
            />
            <Route
              path="/studio/processing-inversion/new-processing"
              element={<ProcessingForm />}
            />
            <Route
              path="/studio/processing-inversion/new-inversion"
              element={<InversionForm />}
            />
            <Route
              path="/studio/processing-inversion/map"
              element={<Map padding={"pl-0.5"} showMetaDataBar={false} />}
            />
            <Route
              path="/studio/processing-inversion/inversion-progress"
              element={<NodeStatus />}
            />
            <Route path="misc-data" element={<MiscData />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </SplitPane>
      ) : (
        <Routes>
          <Route index path="dashboard" element={<ProjectDashboard />} />
          <Route path="dashboard" element={<ProjectDashboard />} />
          <Route
            path="/studio/processing-inversion"
            element={<PlotOrProgressContainer />}
          />
          <Route path="misc-data" element={<MiscData />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </div>
  );
};

export default ProjectWorkspace;
