import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { useNavigate } from "react-router-dom";
import { fetchNodeDetails } from "./api/useNodeData";
import { useActiveProject } from "./useActiveProject";
import { MessagesContext } from "../contexts/MessagesContext";
import { error2message } from "./api/useSafeQuery";

const useCreateImport = (
  activeNode,
  groupId,
  projectId,
  selectedEnvironment,
  title,
  setActiveNode,
  setIsLoading,
  isLoading
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectTitle } = useActiveProject();
  const { addMessage } = useContext(MessagesContext);

  const createImportMutation = useMutation({
    mutationFn: (steps) =>
      client.post(`/api/projects/${projectId}/groups/${groupId}/imports/new`, {
        environment: selectedEnvironment.id,
        title: title,
        config: steps,
      }),
    // onSuccess, onError, etc. callbacks can be defined here for handling the mutation result
    onSuccess: (data) => {
      setIsLoading(false);
      setActiveNode([projectId, groupId, data.data.id]);
      fetchNodeDetails(activeNode);
      queryClient.invalidateQueries("datasets");
      navigate(`/project/${projectTitle}/studio/processing-inversion`);
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoading(false);

        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        setIsLoading(false);
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (steps) => {
    setIsLoading(true);
    createImportMutation.mutate(steps);
  };

  return {
    createImportMutation,
    handleFormSubmit,
    isLoading,
  };
};

export default useCreateImport;
