import React, { useState } from "react";
import {
  InterpretationIcon,
  ArrowUturnLeftIcon,
  BookOpenIcon,
  MapPinIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ProcessingAndInverisonIcon,
} from "../../../assets/icons";
import Logo from "../../../assets/images/logo.png";
import Tooltip from "../../common/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useActiveProject } from "../../../hooks/useActiveProject";

const SideNav = () => {
  const { projectTitle } = useActiveProject();
  let navigate = useNavigate();
  let location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState(location.pathname);

  const dashboardPath = `/project/${projectTitle}/dashboard`;
  const miscDataPath = `/project/${projectTitle}/misc-data`;
  const plotWorkspacePath = `/project/${projectTitle}/studio/processing-inversion/map`;
  const homePath = `/`;

  const navigateAndSetActive = (path) => {
    setActiveNavItem(path);
    navigate(path);
  };

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const supportTooltipText = isWidgetOpen ? "Close support" : "Open support";
  const toggleSupportWidget = () => {
    if (window.FreshworksWidget) {
      if (!isWidgetOpen) {
        window.FreshworksWidget("show", "launcher"); // Show the launcher
        window.FreshworksWidget("open"); // Open widget
      } else {
        window.FreshworksWidget("hide", "launcher"); // Hide the launcher
        window.FreshworksWidget("close"); // Close widget
      }
      setIsWidgetOpen(!isWidgetOpen);
    }
  };

  return (
    <nav className="beryl_sidenav h-screen w-14 min-w-14 max-w-14 bg-transparent flex flex-col gap-2 text-white/90 items-center py-3">

      <IconButtonWithTooltip
          tooltipText="Back to home"
          onClick={() => navigateAndSetActive(homePath)}
          IconComponent={ArrowUturnLeftIcon}
          isActive={activeNavItem === homePath}
        />

<Link
        to={homePath}
        className="mb-4 hover:scale-105 transition ease-in 1s hidden"
        onClick={() => setActiveNavItem(homePath)}
      >
        <img src={Logo} alt="EMerald iconmark" className="h-7 w-7.5" />
      </Link>

      <IconButtonWithTooltip
        tooltipText="Project dashboard"
        onClick={() => navigateAndSetActive(dashboardPath)}
        IconComponent={HomeIcon}
        isActive={activeNavItem === dashboardPath}
      />

      <IconButtonWithTooltip
        tooltipText="Misc data"
        onClick={() => navigateAndSetActive(miscDataPath)}
        IconComponent={MapPinIcon}
        isActive={activeNavItem === miscDataPath}
      />

      <IconButtonWithTooltip
        tooltipText="Processing and inversion"
        onClick={() => navigateAndSetActive(plotWorkspacePath)}
        IconComponent={ProcessingAndInverisonIcon}
        isActive={activeNavItem === plotWorkspacePath}
        buttonClassName="opacity-80 hover:opacity-100"
        iconClassName="p-0.5"
        iconColorProp="white"
      />

      <div className="flex gap-4 items-center hidden">
        <button className="p-2 rounded transition ease-in-out 3s opacity-50 cursor-default">
          <InterpretationIcon className={"opacity-50"} />
        </button>
      </div>

      <div className="mt-auto flex flex-col gap-2 items-center">
        <div className="flex gap-4 items-center">
          <button className="p-2 rounded transition ease-in-out 3s opacity-50 cursor-default">
            <BookOpenIcon className="w-6 h-6" />
          </button>
        </div>

        <IconButtonWithTooltip
          tooltipText={supportTooltipText}
          onClick={() => {
            toggleSupportWidget();
          }}
          IconComponent={QuestionMarkCircleIcon}
          isActive={isWidgetOpen}
        />

      </div>
    </nav>
  );
};

const IconButtonWithTooltip = ({
  tooltipText,
  onClick,
  IconComponent,
  isActive,
  buttonClassName = "",
  iconClassName = "",
  iconColorProp = "",
}) => {
  // Default classes are combined with the custom classes passed via props
  const activeClass = isActive ? "bg-white/15 text-white scale-105" : "";
  const buttonClassNames = `p-2 rounded-md hover:bg-white/15 text-white/80 
  hover:text-white hover:scale-105 
  transition ease-in-out duration-200 
  ${buttonClassName} ${activeClass}`;
  const iconClassNames = `w-5 h-5 ${iconClassName}`;

  return (
    <Tooltip placement="right" tooltipText={tooltipText} tooltipSpacing="3">
      <button onClick={onClick} className={buttonClassNames}>
        <IconComponent className={iconClassNames} color={iconColorProp} />
      </button>
    </Tooltip>
  );
};

export default SideNav;
