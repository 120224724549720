import React, { useState } from "react";
import moment from "moment";
import { TableRow, TableCell, TableHeaderCell } from "../../../../common/Table";
import FileItemMenu from "./FileItemMenu";
import FileItemInfo from "./FileItemInfo";
import { darkenColor, hexToRgba } from "../../../../../utils";
import { EditUploadDetailsModal } from "../EditUploadDetailsModal";
import StylesMark from "./StylesMark";

const FileItem = ({ item }) => {
  const formattedDate = moment(item.created_at).format("MMM D, YYYY");
  const formattedMinutes = moment(item.created_at).format("HH:mm");

  const [isOpen, setIsOpen] = useState(false);
  const [styleLoading, setStyleLoading] = useState(false);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const stylesMarkLoading = () => {
    if (styleLoading) {
      return <StylesMark isLoading={true} />;
    } else {
      return null;
    }
  };

  return (
    <TableRow>
      <TableHeaderCell>
        {item.icon}
        {item.title}
      </TableHeaderCell>

      <TableCell>
        <div className="flex gap-1">
          {item.tags.map((tag) => (
            <div
              key={tag.id}
              className="inline-flex items-center rounded-full text-xs font-medium px-1.5 py-0.5"
              style={{
                backgroundColor: hexToRgba(tag.color, 0.4),
                color: darkenColor(hexToRgba(tag.color), 30),
              }}
            >
              {tag.name}
            </div>
          ))}
        </div>
      </TableCell>
      <TableCell>{formattedDate + ` ` + formattedMinutes}</TableCell>
      <TableCell>
        <div className="flex gap-2 items-center">
          <FileItemInfo src={item.src} datatype={item.datatype} item={item} />
          <FileItemMenu
            src={item.src}
            openModal={handleOpenModal}
            item={item}
            setStyleLoading={setStyleLoading}
          />
          <EditUploadDetailsModal
            isOpen={isOpen}
            closeModal={handleCloseModal}
            auxDataId={item.id}
            auxDataTitle={item.title}
            tags={item.tags}
            projectId={item.project}
          />
          {item.url && item.url.styles ? (
            <StylesMark isLoading={false} />
          ) : (
            stylesMarkLoading()
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default FileItem;
