import { useState, useEffect } from "react";

export const useSubplotZooms = () => {
  const [subplotZooms, setSubplotZooms] = useState(
    () => JSON.parse(localStorage.getItem("subplotZooms")) || {}
  );

  useEffect(() => {
    localStorage.setItem("subplotZooms", JSON.stringify(subplotZooms));
  }, [subplotZooms]);

  return [subplotZooms, setSubplotZooms];
};
