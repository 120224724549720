import React, { useContext, useState } from "react";
import Heading from "../../../common/ui/Heading";
import { MessagesContext, NodeDataContext } from "../../../../contexts";
import { useProject } from "../../../../hooks/api/useProjects";
import useCreateInvite from "../../../../hooks/useCreateInvite";
import { FormInput } from "../../../common/forms/Form";
import { PlusIcon } from "../../../../assets/icons";
import { PopupDialog } from "../../../common/PopupDialog";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { error2message } from "../../../../hooks/api/useSafeQuery";
//import { Map } from "../Map";

const validateEmail = yup.object().shape({
  email: yup
    .string()
    .required("You must provide an email.")
    .email("You must provide a valid email address."),
});

const ProjectDashboard = () => {
  const { activeNode } = useContext(NodeDataContext);
  const { addMessage } = useContext(MessagesContext);
  const project = useProject(activeNode[0])?.data;
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const onSuccess = () => {
    handleCloseModal();
    addMessage({
      title: "Invitation sent",
      shortMessage: `You have sent an invitation to ${email}`,
      type: "success",
      message: "Invitation sent",
    });
  };

  const onError = (error) => {
    handleCloseModal();
    addMessage(error2message(error));
  };

  const { handleFormSubmit } = useCreateInvite(onSuccess, onError, activeNode);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateEmail),
  });

  const onSubmit = () => {
    handleFormSubmit({ email });
  };

  return (
    <div className="w-full h-full pt-3 pb-3 pr-3 pl-2">
      <div className="flex flex-col gap-12 w-full relative h-full bg-white rounded-lg justify-center items-center">
        <button
          type="button"
          onClick={handleOpenModal}
          className="absolute px-3 py-1.5 flex gap-1 items-center rounded-md text-lg text-zinc-800 border border-zinc-800 right-5 top-5"
        >
          <PlusIcon className="w-4 h-4" /> Invite member
        </button>
        <div className="flex flex-col gap-2 justify-center items-center">
          <Heading size="h1">{project?.title}</Heading>
          <div className="max-w-[500px] text-center text-zinc-700">
            There will be more stuff here in the future ...
          </div>
        </div>

        <div className="max-w-1/2 flex flex-col justify-center items-center">
          <Heading size="h3">Members</Heading>
          <ul className="mt-2">
            {project?.owners?.map((owner) => {
              const name =
                owner.first_name && owner.last_name
                  ? owner.first_name + " " + owner.last_name
                  : owner.email;
              return (
                <li
                  key={owner.id}
                  className="bg-emerald-200/70 text-emerald-700 px-3 py-1.5 flex gap-3 items-center rounded-full"
                >
                  {name}
                </li>
              );
            })}
          </ul>
        </div>

        {/*<div className=" w-full rounded-md mt-16 p-1 hidden">
          <div className="w-full h-60 relative">
            <Map showMetaDataBar={false} showMapMenu={false} />
          </div>
        </div>*/}

        <PopupDialog
          isOpen={isOpen}
          closeModal={handleCloseModal}
          title="Invite member"
          width="md"
          description={
            "Be aware that the member will be able to view and edit data."
          }
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-2"
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <FormInput
                  placeholder="example@gmail.com"
                  label="Email"
                  size="sm"
                  error={errors.email}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setEmail(e.target.value);
                  }}
                />
              )}
            />

            <div className="button-group flex flex-row justify-end gap-2">
              <button
                onClick={handleCloseModal}
                type="button"
                className="bg-transparent text-zinc-600 text-sm px-3 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-zinc-800 text-white px-5 py-2 rounded-md"
              >
                Send invite
              </button>
            </div>
          </form>
        </PopupDialog>
      </div>
    </div>
  );
};

export default ProjectDashboard;
