import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "../../assets/icons";

const SimpleSearchSelect = ({
  items,
  selected,
  setSelected,
  maxExamples,
  inputStyles = "relative text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500",
  inputWidth = "w-full",
}) => {
  const [query, setQuery] = useState("");

  let filteredItems = items.filter((item) => {
    const titleMatch = item.title.toLowerCase().includes(query.toLowerCase());
    const idMatch = item.id.toString().includes(query);
    return titleMatch || idMatch;
  });

  maxExamples = maxExamples === undefined ? 10 : maxExamples;

  if (filteredItems.length > maxExamples)
    filteredItems = filteredItems.slice(0, maxExamples);

  const formatDisplayText = (item) => `[EPSG:${item.id}] ${item.title}`;
  const placeholderText = "i.e 31984";

  // system type
  return (
    <div className="w-auto">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative">
          <div
            className={`relative ${inputWidth} cursor-default overflow-hidden rounded-lg bg-white text-left rounded-md`}
          >
            <Combobox.Input
              className={inputStyles}
              displayValue={(item) =>
                item ? formatDisplayText(item) : "Nothing selected"
              }
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholderText}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-4 w-4 text-zinc-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {}}
          >
            <Combobox.Options className="select-options absolute mt-1 max-h-40 z-30 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.length === 0 ? (
                <div className="relative cursor-default select-none py-2 px-3 text-zinc-700">
                  No results found
                </div>
              ) : (
                filteredItems.map((item) => {
                  const displayText = formatDisplayText(item);
                  return (
                    <Combobox.Option
                      disabled={item.unavailable}
                      key={item.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-3 ${
                          active ? "text-zinc-800 bg-zinc-100" : "text-zinc-900"
                        }`
                      }
                      value={item}
                    >
                      {displayText}
                    </Combobox.Option>
                  );
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default SimpleSearchSelect;
