import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { ClipboardIcon, MapPinIcon } from "../../../assets/icons";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { NodeDataContext } from "../../../contexts";
import { useInteractiveStates } from "../../../contexts/InteractiveStateContext";
import { useProjections } from "../../../hooks/api/useProjections";

const MetadataBar = ({
  theme,
  onCopySuccess,
  containerPosition = "absolute",
}) => {
  const containerRef = useRef();
  const utmCoordinatesRef = useRef(null);
  const latlonCoordinatesRef = useRef(null);

  const textColor = theme === "dark" ? "text-zinc-300" : "text-zinc-700";
  const backgroundColor = theme === "dark" ? "bg-zinc-900/80" : "bg-white";
  const hoverTextColor =
    theme === "dark"
      ? "p-1 rounded hover:text-zinc-300/70 hover:bg-zinc-500/50"
      : "p-1 rounded hover:text-zinc-900/70 hover:bg-zinc-500/20";
  const iconColor = textColor; // Assuming you want the icons to match the text color
  const coordinateBgColor = theme === "dark" ? "bg-zinc-900" : "bg-zinc-200";

  const { nodeBinaryByLine } = useContext(NodeDataContext);
  const {
    selectedLineId,
    selectedSoundingId,
    hoveredLineId,
    hoveredSoundingId,
  } = useInteractiveStates();
  const projectionsQuery = useProjections();

  const handleCopy = (ref) => {
    const text = ref.current.innerText;
    copyToClipboard(text);
    onCopySuccess();
  };

  const soundingId =
    hoveredSoundingId != null ? hoveredSoundingId : selectedSoundingId;
  const lineId = hoveredLineId != null ? hoveredLineId : selectedLineId;

  let xdistNum = "-";
  let xdistNumFormatted = "-";
  let xdist = "-";
  let UTMX = "-";
  let UTMY = "-";
  let latitude = "-";
  let longitude = "-";
  let epsg = "-";
  let projection = "";
  let date = "";

  if (nodeBinaryByLine) {
    const binary = Object.values(nodeBinaryByLine)?.[0]?.[lineId];

    if (binary) {
      xdistNum = binary.flightlines.xdist[soundingId];
      xdistNumFormatted = Math.floor(xdistNum);
      xdist = xdistNumFormatted;
      UTMX = binary.flightlines.UTMX[soundingId];
      UTMX = UTMX !== undefined ? Number(UTMX).toFixed(1) : "N/A";
      UTMY = binary.flightlines.UTMY[soundingId];
      UTMY = UTMY !== undefined ? Number(UTMY).toFixed(1) : "N/A";
      latitude = binary.flightlines.lat[soundingId];
      latitude = latitude !== undefined ? Number(latitude).toFixed(6) : "N/A";
      longitude = binary.flightlines.lon[soundingId];
      longitude =
        longitude !== undefined ? Number(longitude).toFixed(6) : "N/A";
      epsg = binary.model_info.projection;
      if (binary.flightlines.DateTime) {
        const extractDate = new Date(
          (binary.flightlines?.DateTime[soundingId] - 25567.0) * 86400000
        );
        // Convert the local date-time to a moment object in UTC
        const utcDateTime = moment.utc(extractDate);
        const formattedDate = utcDateTime.format("MMM D, YYYY HH:mm:ss:SSS");
        date = formattedDate;
      }
    }

    if (projectionsQuery.data) {
      projection = projectionsQuery.data[epsg];
    }
  }

  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`metadatabar bottom-0 w-full text-sm ${containerPosition}`}
    >
      <div
        className={`${textColor} ${backgroundColor} text-xs flex gap-4 items-center backdrop-blur-xl px-4 py-2 flex-nowrap`}
      >
        <div className="flex gap-1.5 items-center flex-nowrap">
          <MapPinIcon className={`w-4 h-4 ${iconColor}`} />
          <div className="flex gap-1.5 items-center flex-nowrap">
            {containerWidth > 1300 && (
              <span className="metadatabar--projection">{projection} |</span>
            )}
            EPSG: {epsg} | UTMX, UTMY
            <span
              ref={utmCoordinatesRef}
              className={`${coordinateBgColor} p-1 px-2 w-fit flex items-center justify-center gap-1.5 rounded flex-nowrap`}
              style={{ width: 170 }}
            >
              {UTMX + ", " + UTMY}
            </span>
            <button
              onClick={() => handleCopy(utmCoordinatesRef)}
              className={hoverTextColor}
            >
              <ClipboardIcon className={`w-3.5 h-3.5 ${iconColor}`} />
            </button>
          </div>
        </div>

        <div className="flex gap-1.5 items-center flex-nowrap">
          WGS84 | LAT, LON
          <span
            ref={latlonCoordinatesRef}
            className={`${coordinateBgColor} p-1 px-2 flex items-center justify-center gap-0.5 rounded flex-nowrap break-keep`}
            style={{ width: 180 }}
          >
            {latitude + ", " + longitude}
          </span>
          <button
            onClick={() => handleCopy(latlonCoordinatesRef)}
            className={hoverTextColor}
          >
            <ClipboardIcon className={`w-3.5 h-3.5 ${iconColor}`} />
          </button>
        </div>

        <div className="ml-auto">
          {date} | LINE {lineId} @ {xdist}m
        </div>
      </div>
    </div>
  );
};

export default MetadataBar;
