import React from "react";
import DatasetMap from "./DatasetMap";
import { MapLayoutProvider } from "../../../../contexts/MapLayoutContext";
import MetadataBar from "../../MetadataBar";
import useCustomAlert from "../../../../hooks/useCustomAlert";
import { CustomAlert } from "../../../common/CustomAlert";

export const Map = ({ padding = "", showMetaDataBar = true, showMapMenu = true }) => {
  const [alertVisible, showAlert] = useCustomAlert();
  return (
    <div className={`wrapper flex ${padding}`}>
      <CustomAlert message="Copied to clipboard" isVisible={alertVisible} />
      <MapLayoutProvider>
        <DatasetMap showMapMenu={showMapMenu} />
        {showMetaDataBar && <MetadataBar theme={"dark"} onCopySuccess={showAlert} />}
      </MapLayoutProvider>
    </div>
  );
};

export default Map;
