import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "../../assets/icons";
import { Fragment } from "react";

const SimpleSelect = ({
  items,
  selected,
  setSelected,
  nothingTitle,
  buttonStyles = "relative h-7 text-sm w-full bg-transparent py-1 px-2 flex items-center gap-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500",
  IconComponent = null,
  iconPosition = "before",
  width = "w-36",
  showCheckIcon = false,
  renderOption = null,
  renderOptionsContent = null,
  dropdownWidth = "w-full",
  extraTitle,
  iconClasses = "",
}) => {
  if (selected === undefined) selected = null;

  return (
    <Listbox className={width} value={selected} onChange={setSelected}>
      <div className="relative">
        <Listbox.Button className={buttonStyles}>
          {iconPosition === "before" && IconComponent && (
            <IconComponent className="h-4 w-4 text-zinc-700" />
          )}
          <span className="block truncate flex items-center gap-2 justify-between">
            {selected ? selected.title : nothingTitle || "Nothing selected"}
          </span>
          {iconPosition === "after" && IconComponent && (
            <IconComponent className={`h-4 w-4 text-zinc-400 ${iconClasses}`} />
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`absolute z-10 mt-1 ${dropdownWidth} bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
          >
            {renderOptionsContent
              ? renderOptionsContent()
              : items?.map((item) => (
                  <Listbox.Option
                    key={item.id === undefined ? "undefined" : item.id}
                    value={item}
                    as={Fragment}
                  >
                    {({ active, selected }) => {
                      if (renderOption) {
                        return renderOption({ item, active, selected });
                      }

                      return (
                        <li
                          className={`
                ${active ? "text-zinc-800 bg-zinc-100" : "text-zinc-900"}
                ${selected ? "bg-zinc-100" : "font-regular"}
                relative py-2 pr-4
                ${showCheckIcon && selected ? "pl-10" : "pl-4"}
              `}
                        >
                          {showCheckIcon && selected && (
                            <CheckIcon className="absolute left-3 h-5 w-5 text-zinc-800 font-medium" />
                          )}
                          <div className="flex justify-between items-center gap-2">
                            {item?.title} {extraTitle && extraTitle(item)()}
                          </div>
                        </li>
                      );
                    }}
                  </Listbox.Option>
                ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SimpleSelect;
