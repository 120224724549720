import React, { useContext } from "react";
import { NodeDataContext } from "../../../../../../contexts";
import {
  ActivatePointsIcon,
  DeactivatePointsIcon,
} from "../../../../../../assets/icons";
import TooltipButton from "../../../../../common/TooltipButton";
import { elements } from "../../PlotElements";
import { usePlotContext } from "../../PlotContext";
import { apply_selections } from "emerald-plotly-react";

const ManualEditButtons = ({ isManualEditSelect }) => {
  const { selections } = useContext(NodeDataContext);
  const plotContext = usePlotContext();

  const subtractSelections = () => {
    apply_selections(elements, plotContext, selections, {
      set_in_use: { value: 0 },
    });
  };

  const addSelections = () => {
    apply_selections(elements, plotContext, selections, {
      set_in_use: { value: 1 },
    });
  };

  return (
    <div
      className={`flex items-center rounded-md ${
        !isManualEditSelect && "opacity-40 pointer-events-none"
      }`}
    >
      <TooltipButton
        onClick={subtractSelections}
        tooltipText="Deactivate points"
        tooltipSpacing={"3"}
        icon={<DeactivatePointsIcon />}
      />
      <TooltipButton
        onClick={addSelections}
        tooltipText="Activate points"
        tooltipSpacing={"3"}
        icon={<ActivatePointsIcon />}
      />
    </div>
  );
};

export default ManualEditButtons;
