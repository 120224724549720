import React, { useContext } from "react";
import { PopupDialog } from "../../common/PopupDialog";
import { SidebarContext } from "../../../contexts/SidebarContext";
import EditGroupForm from "../forms/EditGroupForm";
import EditTitleForm from "../forms/EditTitleForm";

const EditDialog = ({ isOpen, closePopup, isEditMode }) => {
  const { nodeItemClicked } = useContext(SidebarContext);

  const dialogTitle =
    nodeItemClicked && nodeItemClicked?.data_type === "group"
      ? isEditMode
        ? "Edit group"
        : "Create group"
      : "Rename";

  const content =
    nodeItemClicked?.data_type === "group" ? (
      <EditGroupForm
        isEditMode={isEditMode}
        groupData={nodeItemClicked}
        closePopup={closePopup}
      />
    ) : (
      <EditTitleForm data={nodeItemClicked} closePopup={closePopup} />
    );

  return (
    <PopupDialog
      isOpen={isOpen}
      closeModal={closePopup}
      title={dialogTitle}
      width="md"
    >
      {content}
    </PopupDialog>
  );
};

export default EditDialog;
