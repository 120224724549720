import Plot from "react-plotly.js";
import Heading from "../../../common/ui/Heading";
import { useNodeProgress } from "../../../../hooks/api/useNodeProgress";
import { useEffect, useState } from "react";

const ProgressPlot = ({ containerRef, nodeData, activeNode, plotWidth }) => {
  const { plots } = useNodeProgress(activeNode);
  const [visibleTraces, setVisibleTraces] = useState({});

  useEffect(() => {
    // Initialize visibility state: only "rmse_d" is visible by default
    const initialVisibility = {};
    plots.forEach((trace) => {
      initialVisibility[trace.name] = trace.name === "rmse_d";
    });
    setVisibleTraces(initialVisibility);
  }, [plots]);

  const handleLegendClick = (event) => {
    const traceName = event.data[event.curveNumber].name;
    setVisibleTraces((prevState) => ({
      ...prevState,
      [traceName]: !prevState[traceName],
    }));
    // Prevent the default plotly behavior of hiding the trace
    return false;
  };

  const modifiedPlots = plots.map((trace) => ({
    ...trace,
    visible: visibleTraces[trace.name] ? true : "legendonly",
  }));
  return (
    <div
      ref={containerRef}
      className="progress-plot-container w-full bg-white rounded-md py-4 px-6 shadow-sm shadow-zinc-200"
    >
      <Heading size="h5">{`Progress of ${nodeData?.title}`}</Heading>
      <Plot
        data={modifiedPlots}
        layout={{
          width: plotWidth,
          height: 450,
          xaxis: {
            title: "Iterations",
          },
          yaxis: {
            type: "log",
          },
          margin: {
            b: 40,
            l: 40,
            r: 20,
            t: 30,
          },
        }}
        config={{
          responsive: true,
          displaylogo: false,
          displayModeBar: true,
          modeBarButtonsToRemove: ["zoomIn2d", "zoomOut2d", "resetScale2d"],
        }}
        onLegendClick={handleLegendClick}
      />
    </div>
  );
};

export default ProgressPlot;
