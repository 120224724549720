import { useContext } from "react";
import { NodeDataContext } from "../../../../contexts";
import GroupDetails from "./GroupDetails";

const ElementDetailsContainer = ({ handleOpenModal, setIsEditMode }) => {
  const { nodeData, nodeType } = useContext(NodeDataContext);

  return (
    <div className="sidebar__element-info">
      {nodeData && nodeType === "group" && (
        <GroupDetails
          group={nodeData}
          handleOpenModal={handleOpenModal}
          setIsEditMode={setIsEditMode}
        />
      )}
    </div>
  );
};

export default ElementDetailsContainer;
