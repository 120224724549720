import { useSafeQuery } from "./useSafeQuery";
import client from "../../api/client";

export const fetchProjections = async () => {
  const { data } = await client.get(`/api/projections/byepsg`);
  return data;
};

export function useProjections() {
  return useSafeQuery(["projections"], fetchProjections);
}
