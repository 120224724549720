import React, { useCallback, useContext, useEffect, useState } from "react";
import CreateProjectForm from "./CreateProjectForm";
import { useProjects } from "../../../../hooks/api/useProjects";
import ErrorPage from "../../../common/ErrorPage";
import Heading from "../../../common/ui/Heading";
import { NodeDataContext } from "../../../../contexts";
import ProjectCard from "./ProjectCard";
import { PopupDialog } from "../../../common/PopupDialog";
import { LoadingPlaceholder } from "../../../common/LoadingPlaceholder";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../../../utils";

const Projects = () => {
  const { setActiveNode } = useContext(NodeDataContext);
  const { data: projects, isLoading, error } = useProjects();

  const [filteredProjects, setFilteredProjects] = useState([]);

  const saveProjectDetails = (title, id) => {
    const projectInfo = { title: title, projectId: id };
    localStorage.setItem("activeProject", JSON.stringify(projectInfo));
  };

  const saveRecentlyViewed = (projectId) => {
    let recentlyViewed =
      JSON.parse(localStorage.getItem("recentlyViewedProjectIds")) || [];
    recentlyViewed = recentlyViewed.filter((id) => id !== projectId); // Remove the project ID if it already exists
    recentlyViewed.unshift(projectId); // Add the project ID to the start
    recentlyViewed = recentlyViewed.slice(0, 4); // Limit number of entries
    localStorage.setItem(
      "recentlyViewedProjectIds",
      JSON.stringify(recentlyViewed)
    );
  };

  const updateFilteredProjects = useCallback(() => {
    const storedProjectIds =
      JSON.parse(localStorage.getItem("recentlyViewedProjectIds")) || [];

    const updatedFilteredProjects = storedProjectIds
      .map((id) => projects?.find((project) => project.id === id))
      .filter((project) => project !== undefined); // Filter out any undefined entries in case a project ID wasn't found

    setFilteredProjects(updatedFilteredProjects);
  }, [projects]);

  useEffect(() => {
    updateFilteredProjects();
  }, [updateFilteredProjects]);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenPopup = () => setIsOpen(true);
  const handleClosePopup = () => setIsOpen(false);

  let navigate = useNavigate();

  const handleProjectClick = async (project) => {
    saveRecentlyViewed(project.id);
    setActiveNode([project.id]);
    saveProjectDetails(project.title, project.id);
    updateFilteredProjects();

    const title = slugify(project.title);
    // Navigate after all actions are complete
    navigate(`/project/${title}/dashboard`);
  };

  if (isLoading) return <LoadingPlaceholder />;
  if (error)
    return (
      <ErrorPage
        message={`${"Ooops 😣 An error occured: "} ${error.message}`}
      />
    );

  return (
    <div>
      <div className="m-auto mt-12 mb-12 max-w-3xl p-5">
        <div className="flex flex-col gap-8">
          <Heading size="h1">Projects</Heading>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <div className="text-zinc-800 font-medium">Recents</div>
              <div className="w-full" key={"new-project-button"}>
                <ProjectCard
                  title={"New project"}
                  onClick={handleOpenPopup}
                  variant="create"
                />
              </div>
              <ul className="flex gap-3 gap-y-3 max-w-3xl flex-wrap">
                {filteredProjects?.length > 0 ? (
                  filteredProjects.map((project) => (
                    <li className="w-full" key={project.id}>
                      <ProjectCard
                        title={project.title}
                        projectId={project.id}
                        onClick={() => handleProjectClick(project)}
                      />
                    </li>
                  ))
                ) : (
                  <li className="w-full">
                    <div className="text-center text-zinc-600">
                      No recent projects to display.
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-zinc-800 font-medium">All projects</div>
              <ul className="flex gap-3 gap-y-3 max-w-3xl flex-wrap">
                {projects
                  ?.slice()
                  .reverse()
                  .map((project) => (
                    <li className="w-full" key={project.id}>
                      <ProjectCard
                        title={project.title}
                        projectId={project.id}
                        onClick={() => handleProjectClick(project)}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PopupDialog
        isOpen={isOpen}
        closeModal={handleClosePopup}
        title="Create new project"
        width="md"
      >
        <CreateProjectForm
          closePopup={handleClosePopup}
          existingProjects={projects}
        />
      </PopupDialog>
    </div>
  );
};

export default Projects;
