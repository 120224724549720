export const AuxData = ({ column, unit, title, titleShort, color, hasChannels }) => {
  return {
    title: function (context, args) {
      if (titleShort === undefined) return;
      return titleShort + " " + args.dataset + (args.channel ? " " + args.channel : "");
    },
    fn: function (context, args, elements) {
      let { selectedLineId, nodeBinaryByLine } = context;
      const binary = nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary?.flightlines) return [];
      const xdist = binary.flightlines.xdist;
      const y = binary.flightlines[args.channel || column];

      const xUnit = elements.xaxis[this.xaxis].shortTitle;
      const yUnit = elements.yaxis[this.yaxis].shortTitle;

      const hoverTemplate = `%{x:.1f}${xUnit}, %{y:.6}${yUnit} ${title} <extra>${args.dataset}</extra>`;

      const lineWidth = 1.2;
      return [
        {
          name: title + " " + args.dataset,
          x: xdist,
          y: y,
          line: {
            color: color,
            width: lineWidth,
          },
          legendgroup: title + args.dataset,
          showlegend: false,
          hovertemplate: hoverTemplate,
        },
      ];
    },
    xaxis: "xdist",
    yaxis: unit,
    schema: (context) => {
      if (hasChannels) {
        const datasets = Object.keys(context.nodeBinaryByLine).filter((dataset) => {
          if (!context.nodeBinaryByLine[dataset]) return false;
          return (
            Object.keys(Object.values(context.nodeBinaryByLine[dataset])?.[0]?.flightlines).filter(
              (col) => col.indexOf(column) === 0
            ).length > 0
          );
        });
        const channels = [
          ...new Set(
            datasets
              .map((dataset) => {
                return Object.keys(Object.values(context.nodeBinaryByLine[dataset])?.[0]?.flightlines).filter(
                  (col) => col.indexOf(column) === 0
                );
              })
              .flat()
          ),
        ];
        if (!datasets.length || !channels.length) {
          return false;
        }
        return {
          type: "object",
          properties: {
            dataset: {
              type: "string",
              enum: datasets,
            },
            channel: {
              type: "string",
              enum: channels,
            },
          },
          additionalProperties: false,
        };
      } else {
        const datasets = Object.keys(context.nodeBinaryByLine).filter((dataset) => {
          if (!Object.values(context.nodeBinaryByLine[dataset])?.[0]?.flightlines) return false;
          return !!Object.values(context.nodeBinaryByLine[dataset])?.[0]?.flightlines[column];
        });
        if (!datasets.length) {
          return false;
        }
        return {
          type: "object",
          properties: {
            dataset: {
              type: "string",
              enum: datasets,
            },
          },
          additionalProperties: false,
        };
      }
    },
  };
};

export const Pitch = AuxData({
  column: "TxPitch",
  unit: "degrees",
  title: "Pitch",
  titleShort: "Pitch",
  color: "rgb(0 128 0)",
});

export const Roll = AuxData({
  column: "TxRoll",
  unit: "degrees",
  title: "Roll",
  titleShort: "Roll",
  color: "rgb(0 0 128)",
});

export const GroundSpeed = AuxData({
  column: "GdSpeed",
  unit: "kmh",
  title: "Ground speed",
  titleShort: "GS",
  color: "rgb(128 128 0)",
});

export const RMF = AuxData({
  column: "rmf",
  unit: "nT",
  title: "Residual magnetic field",
  titleShort: "RMF",
  color: "rgb(0 0 192)",
});

export const TMI = AuxData({
  column: "tmi",
  unit: "nT",
  title: "Total magnetic intensity",
  titleShort: "TMI",
  color: "rgb(0 0 192)",
});

export const PowerLineMonitor = AuxData({
  column: "PowerLineMonitor",
  unit: "powerline",
  title: "Power Line Noise Intensity",
  titleShort: "Powerline",
  color: "rgb(64 64 64)",
});

export const Misfit = AuxData({
  column: "resdata",
  unit: "misfit",
  title: "Misfit",
  titleShort: "misfit",
  color: "rgb(255 0 0)",
});
Misfit.title = function (context, args) {
  let { selectedLineId, nodeBinaryByLine } = context;
  const binary = nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
  if (!binary?.flightlines?.resdata) return "Misfit";
  const isPositiveNotNaN = (a) => !isNaN(a) && a > 0;

  const numVals = Object.values(binary.layer_data.dbdt_err_ch1gt)
    .map((col) => {
      return col.map(isPositiveNotNaN).reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0);

  const squareSum = Object.values(binary.layer_data.dbdt_err_ch1gt)
    .map((col) => {
      return col
        .filter(isPositiveNotNaN)
        .map((a) => a * a)
        .reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0);

  const rmse = Math.sqrt(squareSum / numVals);

  return "Misfit: " + rmse.toFixed(2);
};

export const Topo = AuxData({
  column: "Topography",
  unit: "elevation",
  title: "topo",
  color: "rgb(170 100 0)",
});

export const Altitude = AuxData({
  column: "Alt",
  unit: "altitude",
  title: "TX altitude",
  color: "rgb(127 127 255)",
});

export const Current = AuxData({
  column: "Current_Ch",
  unit: "ampere",
  title: "Tx Current",
  titleShort: "Current",
  color: "rgb(128 0 128)",
  hasChannels: true,
});

export const DipoleMoment = AuxData({
  column: "DipoleMoment_Ch",
  unit: "moment",
  title: "Dipole moment",
  titleShort: "Moment",
  color: "rgb(128 0 128)",
  hasChannels: true,
});
