import { SchemaComponent } from "./fieldRegistration";

export const renderFormInputs = ({ properties, value, setValue, ...props }) => {
  return Object.entries(properties).map(([key, prop]) => {
    let subValue = value[key];

    const handleSetValue = (subValue) => {
      const newValue = { ...value };
      newValue[key] = subValue;
      setValue(newValue);
    };

    return (
      <SchemaComponent
        {...props}
        key={key}
        schemaFragment={prop}
        value={subValue}
        propKey={key}
        setValue={handleSetValue}
      />
    );
  });
};
