import React, { useState, Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "../../../../../../assets/icons";
import { getRandomColor, capitalizeFirstLetter } from "../../../../../../utils";
import { Tag } from "./Tag";
import { FormLabel } from "../../../../../common/forms/Form";

export const TagsField = ({
  handleTagsChange,
  tags,
  examples,
  error,
  label,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1); 

  const filteredExamples = examples.filter(
    (example) =>
      example.name.toLowerCase().includes(inputValue.toLowerCase()) &&
      !tags.some((tag) => tag.id === example.id)
  );
  
  
  const addTag = (tag) => {
  
    if (!tag) {
      // Check if a tag with the input value already exists in the examples
      const existingExample = examples.find(
        (t) => t.name.toLowerCase() === inputValue.toLowerCase()
      );
      
      if (existingExample) {
        // If the tag already exists in examples, set it as the tag to be added
        tag = existingExample;
      } else if (inputValue) {
        // If inputValue is not empty and not already an example, create a new tag
        const maxIdFromExamples = Math.max(...examples.map((e) => e.id), 0); // 0 is a fallback
        const maxIdFromTags = Math.max(...tags.map((t) => t.id), 0); // 0 is a fallback
        const newId = Math.max(maxIdFromExamples, maxIdFromTags) + 1;
  
        const capitalizedInputValue = capitalizeFirstLetter(inputValue);
        const newColor = getRandomColor();
  
        tag = { id: newId, name: capitalizedInputValue, color: newColor }; // Create a new tag with the calculated ID and random color
      } else {
        return;
      }
    }
  
    const isTagAlreadyAdded = tags.some((t) => t.id === tag.id);
  
    if (isTagAlreadyAdded) {
      // If tag already exists, remove it
      handleTagsChange(tags.filter((t) => t.id !== tag.id));
    } else {
      // If tag doesn't exist, add it
      handleTagsChange([...tags, tag]);
    }
  
    setInputValue("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (activeIndex >= 0 && activeIndex < filteredExamples.length) {
        addTag(filteredExamples[activeIndex]);
      } else {
        const exist = tags.find(
          (t) => t.name.toLowerCase() === event.target.value.toLowerCase()
        );
        if (exist) {
          addTag(exist);
        } else {
          addTag();
        }
      }
    } else if (event.key === "ArrowDown") {
      setActiveIndex((prevIndex) => 
        Math.min(prevIndex + 1, filteredExamples.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      setActiveIndex((prevIndex) => 
        Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === " ") {
      if (activeIndex >= 0 && activeIndex < filteredExamples.length) {
        addTag(filteredExamples[activeIndex]);
      }
    }
  };

  

  const removeTag = (tagToRemove) => {
    handleTagsChange(tags.filter((tag) => tag.name !== tagToRemove.name));
  };

  return (
    <div className="w-auto">
      <FormLabel label={label} htmlFor={"tags"} />
      <Combobox>
        <div className="relative">
          <div className="flex gap-1.5 gap-y-0.5 flex-wrap items-center">
            {tags.map((tag) => (
              <Tag key={tag.id} onRemove={removeTag} tag={tag} />
            ))}
          </div>
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left rounded-md mt-2">
            <Combobox.Input
              className="relative text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
            />

            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-4 w-4 text-zinc-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {}}
          >
            <Combobox.Options className="absolute mt-1 max-h-44 z-50 w-full overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredExamples.map((example, index) => (
                <Combobox.Option
                  key={example.id}
                  value={example}
                  onClick={() => addTag(example)}
                  className={({ active }) =>
                    `flex items-center gap-2 relative cursor-default select-none py-2 p-4 ${
                      active || activeIndex === index ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-900'
                    }`
                  }
                >
                  {/* Check if the example is already a selected tag */}
                  {tags.some((tag) => tag.id === example.id) ? (
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <div
                      className="h-3 w-3 rounded-full"
                      style={{ backgroundColor: example.color }}
                    ></div>
                  )}
                  {example.name}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      <div className="text-sm text-zinc-500 mt-1">
        {error && <p className="text-red-500">{error.message}</p>}
        Press enter to create new tag
      </div>
    </div>
  );
};
