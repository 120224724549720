import React, { createContext, useState, useContext } from "react";

export const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [sidebarResized, setSidebarResized] = useState(false);
  const [nodeItemClicked, setNodeItemClicked] = useState(null);

  // Function to toggle the resize state
  const toggleSidebarResized = () => {
    setSidebarResized((prev) => !prev);
  };

  const value = {
    sidebarResized,
    toggleSidebarResized,
    nodeItemClicked,
    setNodeItemClicked,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
