import React from "react";
import { iconColor } from "./tools/color";

const ShrinkSidebarIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.8"
        y="0.8"
        width="18.4"
        height="18.4"
        rx="3.2"
        stroke={iconColor}
        strokeWidth="1.6"
      />
      <path
        d="M7 1V19"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M13.6 13.2L11 10.6L13.6 8"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShrinkSidebarIcon;
