import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { SchemaComponent } from "../fieldRegistration";
import { useState } from "react";

export const Step = ({
  schemaFragment,
  stepTitle,
  index,
  onRemove,
  value,
  setValue,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-3 px-2 border-b border-zinc-300 bg-white flex flex-col gap-y-2">
      <div className="flex justify-between items-center">
        <span className="flex items-center gap-3 font-medium">
          <div className="bg-emerald-900/70 text-sm text-white rounded-lg w-7 h-7 flex items-center justify-center p-1 capitalize">
            {typeof index === "string" ? index.charAt(0) : index + 1}
          </div>
          {stepTitle}
        </span>
        <div className="flex justify-between items-center gap-1">
          <button
            type="button"
            className="hover:bg-zinc-100 hover:text-zinc-800 text-zinc-700 font-bold p-1 rounded-md"
            onClick={toggleOpen}
          >
            {isOpen ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </button>
          {onRemove !== undefined && (
            <button
              type="button"
              onClick={onRemove}
              className="hover:bg-zinc-100 hover:text-zinc-800 text-zinc-700 font-bold p-1 rounded-md"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="ml-3.5 pl-6 border-l border-zinc-300">
          <div className="flex flex-col">
            <SchemaComponent
              {...props}
              schemaFragment={schemaFragment}
              value={value}
              setValue={setValue}
              topLevelObject={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};
