import React, { useContext, useEffect, useState } from "react";
import { NodeStatus } from "../workspace/modules/NodeStatus";
import { NodeDataContext } from "../../contexts";
import Plotting from "./modules/Plotting";
import useInvalidateQueryOnStatus from "../../hooks/useInvalidateQueryOnStatus";
import LoadingScreen from "../common/ui/LoadingScreen";
import { useQueryClient } from "@tanstack/react-query";

const PlotOrProgressContainer = () => {
  const { nodeData, nodeType, activeNode } =
    useContext(NodeDataContext);
  const queryClient = useQueryClient();

  useInvalidateQueryOnStatus("nodeData", nodeType);

  useEffect(() => {
    let interval;
    // If the status is "running", set up an interval to invalidate a specific query
    if (nodeData?.status === "running") {
      let time = 5000;

      if (nodeType === "inversion") {
        time = 60000;
      }

      interval = setInterval(() => {
        queryClient.invalidateQueries("nodeData");
        if (nodeType === "inversion") {
          queryClient.invalidateQueries("inversions");
        } else if (nodeType === "processing") {
          queryClient.invalidateQueries("processings");
        }
      }, time); // Invalidate every 5 seconds
    }

    // Cleanup: clear the interval when the component is unmounted or status changes
    return () => {
      clearInterval(interval);
    };
  }, [nodeData, queryClient, nodeType]);

  const [status, setStatus] = useState();
  
  // Relead binary when ready
  useEffect(() => {
    if (nodeData) {
      if (nodeData.status === "done" && status === "running") {
        queryClient.invalidateQueries(["nodeBinary", activeNode, nodeData?.id]);
      }
      if (nodeData.status !== status) {
        setStatus(nodeData.status);
      }
    }
  }, [queryClient, activeNode, nodeData, status]);

  if (!nodeData) {
    return <LoadingScreen />;
  }

  if (nodeData.status === "done") {
    return <Plotting />;
  } else {
    return <NodeStatus />;
  }
};

export default PlotOrProgressContainer;
