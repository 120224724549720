import React from "react";
import { FormLabel } from "../../../../common/forms/Form";

export const TextField = React.forwardRef(
  ({ label, htmlFor, placeholder, error, ...props }, ref) => {
    return (
      <div className="flex flex-col">
        <FormLabel htmlFor={htmlFor} label={label} />
        <textarea
          ref={ref}
          placeholder={placeholder}
          className="block w-full 
        rounded-md 
        placeholder:text-zinc-500 
        focus:ring-0 focus:ring-inset 
        sm:leading-6 focus:outline-none focus:border-zinc-500
        text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500
        "
          {...props}
        />
        {error && <p className="text-red-600 text-sm p-0.5">{error.message}</p>}
      </div>
    );
  }
);
