import React, { useState } from "react";
import { ArrowUpTrayIcon } from "../../../../assets/icons";
import { useEnvironments } from "../../../../hooks/api/useEnvironments";
import { LoadingPlaceholder } from "../../../common/LoadingPlaceholder";
import FlyoutContainer from "../../../common/FlyoutContainer";
import EnvironmentForm from "./EnvironmentForm";
import EnvironmentsList from "./EnvironmentList";
import EnvironmentDetails from "./EnvironmentDetails";
import Heading from "../../../common/ui/Heading";

const EnvironmentManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [environmentBase, setEnvironmentBase] = useState(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState(undefined);

  const { data: environments, isLoading, error } = useEnvironments();

  const createEnvironment = (base) => {
    setEnvironmentBase(base);
    setShowForm(true);
  };

  const showEnvironment = (environmentId) => {
    setSelectedEnvironment(environmentId);
  };

  return (
    <div className="w-full h-full">
      {showForm && (
        <FlyoutContainer
          heading="Create environment"
          toggleVisibility={setShowForm}
          position="right"
        >
          <EnvironmentForm
            setShowForm={setShowForm}
            environmentBase={environmentBase}
          />
        </FlyoutContainer>
      )}
      {selectedEnvironment !== undefined && (
        <EnvironmentDetails
          setEnvironmentId={setSelectedEnvironment}
          environmentId={selectedEnvironment}
        />
      )}
      <div className="p-4 px-8 flex flex-col gap-3 w-full relative h-full bg-white">
        <div className="flex justify-between items-center">
          <Heading size="h4">Environments </Heading>

          <button
            onClick={() => createEnvironment()}
            className="bg-zinc-600 text-white text-sm pl-3 pr-4 py-2 rounded-md hover:bg-zinc-700 flex items-center gap-1.5 mb-2"
          >
            <ArrowUpTrayIcon className="w-4 h-4" /> Create
          </button>
        </div>
        <div className="section">
          {isLoading ? (
            <LoadingPlaceholder />
          ) : environments ? (
            <EnvironmentsList
              data={environments}
              showEnvironment={showEnvironment}
              createEnvironment={createEnvironment}
            />
          ) : (
            error && <div>Error loading data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnvironmentManager;
