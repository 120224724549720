import React from "react";

const Paragraph = ({ id, size = "p2", className = "", children, html }) => {
  let style = "font-regular text-zinc-600";

  switch (size) {
    case "p1":
      style += " text-lg";
      break;
    case "p2":
      style += " text-base";
      break;
    case "p3":
      style += " text-sm";
      break;
    case "p4":
      style += " text-xs";
      break;
    default:
      break;
  }

  const combinedClassNames = `${style} ${className}`;

  return html ? (
    <p
      id={id}
      className={combinedClassNames}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : (
    <p id={id} className={combinedClassNames}>
      {children}
    </p>
  );
};

export default Paragraph;
