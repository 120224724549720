import { Popover } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from "../../../../../assets/icons";
import React, { useEffect, useRef, useState } from "react";
import StylesMark from "./StylesMark";

const FileItemInfo = ({ src, datatype, item }) => {
  const panelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const adjustPosition = () => {
    const panel = panelRef.current;
    if (!panel) return;

    const rect = panel.getBoundingClientRect();
    const { innerHeight } = window;

    let newTop = -2; // initial top position

    // Check for bottom overflow and adjust with padding on bottom
    if (rect.bottom > innerHeight) {
      newTop = innerHeight - rect.height - rect.top - 20;
    }
    panel.style.top = `${newTop}px`;
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("resize", adjustPosition);
    } else {
      window.removeEventListener("resize", adjustPosition);
    }

    return () => {
      window.removeEventListener("resize", adjustPosition);
    };
  }, [isOpen]);

  function extractFilenameFromPath(path) {
    const lastSegment = path.split("/").pop();
    const filename = lastSegment.split("-").pop();
    return filename.replace(/%/g, " ");
  }

  return (
    <Popover className="relative">
      <Popover.Button
        onClick={() => {
          setIsOpen(!isOpen);
          setTimeout(adjustPosition, 0);
        }}
      >
        <InformationCircleIcon className="w-5 h-5" />
      </Popover.Button>

      <Popover.Panel
        ref={panelRef}
        className="absolute info-pop min-w-48 w-fit max-w-72 right-7 -top-2 bg-white rounded-md border border-zinc-100 shadow-md"
      >
        <div className="flex flex-col gap-1 divide-y break-words">
          <div className="py-1.5 pt-2 px-3 flex flex-col gap-1">
            <div className="font-medium">Filename</div>
            <div className="text-zinc-600">{extractFilenameFromPath(src)}</div>
          </div>
          <div className="py-1.5 px-3 flex flex-col gap-1">
            <div className="font-medium">Filetype</div>
            <div className="text-zinc-600">{datatype.mime}</div>
          </div>
          <div className="py-1.5 px-3 flex flex-col gap-1">
            <div className="font-medium">Datatype</div>
            <div className="text-zinc-600">{datatype.title}</div>
          </div>

          {item.url && (
            <div className="py-1.5 px-3 flex flex-col gap-1">
              <div className="font-medium flex gap-1.5 items-center pt-1 w-full -mt-1">
                <div className="-mt-1">Styles</div>
                <StylesMark />
                <a
                  download
                  href={`/rasterserver/download/project/${item.project}/raster/${item.id}`}
                  className={`ml-auto text-xs flex gap-1 relative group hover:bg-emerald-900/10 rounded p-1`}
                >
                  <ArrowDownTrayIcon
                    aria-hidden="true"
                    className="w-3.5 h-3.5"
                  />
                  <div className=" bg-zinc-800 text-zinc-200 shadow rounded-md p-1.5 px-2 absolute mt-5 -ml-20 text-xs opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200">
                    Download zip
                  </div>
                </a>
              </div>
              {item.url && item.url.styles ? (
                <ul className="flex flex-col gap-0.5 p-0.5 max-h-[100px] rounded-md border border-zinc-100 bg-zinc-50 px-2 overflow-scroll">
                  {Object.keys(item.url.styles).map((key) => (
                    <li
                      key={key}
                      className="text-zinc-600 flex items-center gap-2"
                    >
                      <div
                        aria-label={key}
                        title={key}
                        className="text-nowrap text-ellipsis truncate clip"
                      >
                        {key}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="text-zinc-600">No styles applied yet</div>
              )}
            </div>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default FileItemInfo;
