import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";

const useCreateProject = () => {
  const queryClient = useQueryClient();

  const createProjectMutation = useMutation({
    mutationFn: (data) =>
      client.post("/api/projects/", {
        title: data.title,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
    },
  });

  const handleFormSubmit = (data) => {
    createProjectMutation.mutate(data);
  };

  return {
    createProjectMutation,
    handleFormSubmit,
  };
};

export default useCreateProject;
