import React, { useState } from "react";
import { ActionInversionIcon, InversionIcon } from "../../../assets/icons";
import { useInversions } from "../../../hooks/api/useInversions";
import { handleToggleClick } from "./handlers";
import useInvalidateQueryOnStatus from "../../../hooks/useInvalidateQueryOnStatus";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { EmptyListPrompt, NavItem, NavList } from ".";
import { darkenColor, hexToRgba } from "../../../utils";
import { useActiveProject } from "../../../hooks/useActiveProject";

const InversionList = ({
  groupId,
  datasetId,
  processingId,
  groupColor,
  activePath,
  onNodeClick,
  ActionButtons,
  openEditDialog,
}) => {
  const [openNodes, setOpenNodes] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const { projectId } = useActiveProject();
  const { data: inversions, isLoading: isLoadingInversions } = useInversions(
    projectId,
    groupId,
    datasetId,
    processingId
  );

  const sortedArray = inversions?.sort((a, b) => b.id - a.id);

  useInvalidateQueryOnStatus("inversions", "inversion");

  const handleNodeClick = async (id) => {
    onNodeClick([projectId, groupId, datasetId, processingId, id]);
  };

  if (isLoadingInversions || !inversions)
    return (
      <div className="p-2">
        <LoadingPlaceholder height="h-3" rows={1} />
      </div>
    );

  return (
    <NavList>
      {sortedArray.length === 0 && (
        <EmptyListPrompt
          icon={<ActionInversionIcon />}
          text={"to invert data"}
        />
      )}
      {sortedArray?.map((inversion) => (
        <NavItem
          key={inversion.id}
          activePathNumber={4}
          onMouseEnter={() => setHoveredNode(inversion.id)} // set the hovered node ID
          onMouseLeave={() => setHoveredNode(null)} // clear it when the mouse leaves
          nodeItem={inversion}
          activePath={activePath}
          handleToggleClick={() =>
            handleToggleClick(inversion.id, setOpenNodes)
          }
          handleNodeClick={() => handleNodeClick(inversion.id)}
          hoveredNode={hoveredNode}
          openNodes={openNodes}
          groupColor={groupColor}
          openEditDialog={openEditDialog}
          DoneStatusIcon={InversionIcon}
          iconColorProp={darkenColor(hexToRgba(groupColor), 0)}
          ActionButtons={ActionButtons}
        ></NavItem>
      ))}
    </NavList>
  );
};

export default InversionList;
