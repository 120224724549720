import React from "react";

const InterpretationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" fill="white" fillOpacity="1" />
      <circle cx="3.99944" cy="3.99993" r="1.71429" fill="#004E3E" />
      <path
        d="M13.3652 10.3257L11.3652 13.7898"
        stroke="#004E3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.28516 9.14307L3.42801 14.0918"
        stroke="#004E3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.01562 11.5088L7.87277 13.4883"
        stroke="#004E3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="9.71429" cy="7.42864" r="1.71429" fill="#004E3E" />
      <circle cx="11.9994" cy="2.85735" r="1.71429" fill="#004E3E" />
    </svg>
  );
};

export default InterpretationIcon;
