import React, { useState } from "react";
import { PlusIcon, PencilIcon } from "../../../../assets/icons";
import useUpdateProject from "../../../../hooks/useUpdateProject";

const DefaultIcon = () => {
  return <div className="w-3 h-full bg-zinc-800 rounded"></div>;
};
const ProjectCard = ({ title, projectId, onClick, variant = "default" }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  let cardStyles, IconComponent;

  if (variant === "create") {
    cardStyles =
      "w-full rounded-lg p-3 flex gap-3 items-center cursor-pointer border border-dashed border-zinc-600 text-zinc-600 hover:border-zinc-900 hover:text-zinc-900";
    IconComponent = <PlusIcon className="w-6 h-6" />;
  } else {
    cardStyles =
      "h-fit bg-white border border-zinc-200 shadow-sm hover:shadow-lg rounded-lg p-3 flex gap-3 cursor-pointer border";
    IconComponent = <DefaultIcon />;
  }

  const { handleFormSubmit } = useUpdateProject(
    () => setEditMode(false),
    projectId
  );

  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent onClick from firing on the card
    setEditMode(true);
  };

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormSubmit({ title: editedTitle });
    setEditMode(false); // Exit edit mode
  };

  const handleFormClick = (event) => {
    event.stopPropagation();
  };

  const projectTitle = editMode ? (
    <form onSubmit={handleSubmit} onClick={handleFormClick}>
      <input
        type="text"
        value={editedTitle}
        onChange={handleTitleChange}
        autoFocus
        className=" block w-full rounded-md placeholder:text-zinc-500 
        focus:ring-0 focus:ring-inset sm:leading-6 focus:outline-none 
        focus:border-zinc-600 text-sm bg-transparent py-1 px-2 
        border border border-zinc-300 rounded-md hover:border-zinc-600"
      />
      <button type="submit" className="hidden"></button>
    </form>
  ) : (
    <h4 className="text-base font-medium break-words break-all">{title}</h4>
  );

  return (
    <div onClick={onClick} className={cardStyles}>
      <div className="flex gap-3">
        <div>{IconComponent}</div>
        {variant !== "create" ? (
          <div className="py-0.5">
            <div className="text-base font-medium break-words break-all mb-0.5">
              {projectTitle}
            </div>
            <div className="text-sm text-zinc-500 flex items-center gap-1">
              Project
            </div>
          </div>
        ) : (
          <div> {projectTitle}</div>
        )}
      </div>

      {variant !== "create" && (
        <div className="ml-auto">
          <button
            onClick={(event) => handleEditClick(event)}
            className="border border-zinc-300 rounded p-1 hover:border-zinc-400"
          >
            <PencilIcon className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
