import React, { useContext, useState } from "react";
import FilesList from "./FilesList";
import Heading from "../../../common/ui/Heading";
import { ArrowUpTrayIcon, MapIcon } from "../../../../assets/icons";
import MiscDataForm from "./Form";
import { NodeDataContext } from "../../../../contexts";
import { useAllAuxiliaryData } from "../../../../hooks/api/useAuxiliaryData";
import { LoadingPlaceholder } from "../../../common/LoadingPlaceholder";
import FlyoutContainer from "../../../common/FlyoutContainer";
import TooltipButton from "../../../common/TooltipButton";

const MiscData = () => {
  const [showForm, setShowForm] = useState(false);
  const { activeNode } = useContext(NodeDataContext);
  const projectId = activeNode[0];

  const { data: allAuxData, isLoading, error } = useAllAuxiliaryData(projectId);

  const openMap = () =>
    window.open("/views/components/workspace/modules/Map/Map");

  return (
    <div className="w-full h-full">
      {showForm && (
        <FlyoutContainer
          heading="Upload Data"
          toggleVisibility={setShowForm}
          position="right"
        >
          <MiscDataForm projectId={projectId} setShowForm={setShowForm} />
        </FlyoutContainer>
      )}
      <div className="p-4 px-8 flex flex-col gap-3 w-full relative h-full bg-white rounded-tl-lg rounded-bl-lg overflow-y-scroll">
        <div className="flex justify-between items-center">
          <Heading size="h2">Misc data </Heading>

          <div className="flex items-center gap-2 mb-2">
            <TooltipButton
              onClick={openMap}
              tooltipText="Open map"
              icon={<MapIcon className="h-5 w-5" />}
            />

            <button
              onClick={() => setShowForm(true)}
              className="bg-zinc-600 text-white text-sm pl-3 pr-4 py-2 rounded-md hover:bg-zinc-700 flex items-center gap-1.5"
            >
              <ArrowUpTrayIcon className="w-4 h-4" /> Upload
            </button>
          </div>
        </div>
        <div className="section">
          {isLoading ? (
            <LoadingPlaceholder />
          ) : allAuxData ? (
            <FilesList data={allAuxData} />
          ) : (
            error && <div>Error loading data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MiscData;
