import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { MessagesContext } from "../contexts/MessagesContext";
import { error2message } from "./api/useSafeQuery";

const useCreateProcessing = (
  projectId,
  groupId,
  datasetId,
  selectedEnvironment,
  title,
  isTemplate,
  parentProcessingId,
  setActiveNode,
  onSuccessCallback,
  isLoading,
  setIsLoading,
  processing_id // optional, to make a new version of an processing
) => {
  const queryClient = useQueryClient();
  const { addMessage } = useContext(MessagesContext);

  const createProcessingMutation = useMutation({
    mutationFn: (steps) => {
      const endpoint =
        processing_id !== undefined ? `${processing_id}/new` : "new";

      return client.post(
        `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${endpoint}`,
        {
          environment: selectedEnvironment.id,
          title: title,
          steps: steps,
          parent: parentProcessingId,
          is_template: isTemplate,
        }
      );
    },
    // onSuccess, onError, etc. callbacks can be defined here for handling the mutation result
    onSuccess: (data) => {
      setIsLoading(false);
      queryClient.invalidateQueries("datasets");
      queryClient.invalidateQueries("processings");
      setActiveNode([
        projectId,
        groupId,
        datasetId,
        [data.data.processing.id, data.data.id],
      ]);
      onSuccessCallback(data);
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoading(false);

        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        setIsLoading(false);
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (steps) => {
    setIsLoading(true);
    createProcessingMutation.mutate(steps);
  };

  return {
    createProcessingMutation,
    handleFormSubmit,
    isLoading,
  };
};

export default useCreateProcessing;
