import React, { useCallback, useContext, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../../../assets/icons";
import SimpleSelect from "../../../../../common/SimpleSelect";
import { useInteractiveStates } from "../../../../../../contexts/InteractiveStateContext";
import { ProgressLoader } from "../../../../../../api/lazy/ProgressLoader";
import { NodeDataContext } from "../../../../../../contexts";

const ARROW_BUTTON_STYLES = `border border-zinc-300 
  text-zinc-700 py-1 px-0.5 rounded-md leading-tight 
  focus:outline-none focus:bg-transparent focus:border-zinc-500 
  hover:border-zinc-500`;

const LineSelect = () => {
  const { selectedLineId, setSelectedLineId } = useInteractiveStates();
  const { flightlineItems, loadingProgress, initialFlightlineId, flightlines } = useContext(NodeDataContext);

  useEffect(() => {
    if (flightlines.length > 0 && !selectedLineId) {
      //console.log("setting initial flightline", initialFlightlineId);
      setSelectedLineId(initialFlightlineId);
    }
  }, [flightlines, initialFlightlineId, selectedLineId, setSelectedLineId]);

  const selectedFlightline = flightlineItems.find((item) => item.id === selectedLineId);

  const navigateFlightline = useCallback(
    (direction) => {
      const currentIndex = flightlineItems.findIndex((item) => item.id === selectedLineId);
      const newIndex =
        direction === "next" ? Math.min(currentIndex + 1, flightlineItems.length - 1) : Math.max(currentIndex - 1, 0);
      setSelectedLineId(flightlineItems[newIndex].id);
      console.log("navigate flightline", flightlineItems[newIndex].id, selectedLineId);
    },
    [flightlineItems, selectedLineId, setSelectedLineId]
  );

  const selectPreviousOption = useCallback(() => navigateFlightline("previous"), [navigateFlightline]);
  const selectNextOption = useCallback(() => navigateFlightline("next"), [navigateFlightline]);

  return (
    <div className="line-select flex gap-1 items-center">
      {flightlineItems.length > 1 && (
        <button className={ARROW_BUTTON_STYLES} onClick={selectPreviousOption}>
          <ChevronLeftIcon className="h-3 w-4 stroke-2" />
        </button>
      )}

      <SimpleSelect
        items={flightlineItems}
        selected={selectedFlightline}
        setSelected={(item) => {
          console.log("Set flightline", item.id);
          setSelectedLineId(item.id);
        }}
        width="w-24"
        nothingTitle="No flightline selected"
        buttonStyles="relative text-sm w-full bg-transparent flex justify-center border border-1 border-zinc-300 rounded-md z-50 hover:border-zinc-500 p-0.5 px-1.5"
        IconComponent={null}
      />

      {flightlineItems.length > 1 && (
        <button className={ARROW_BUTTON_STYLES} onClick={selectNextOption}>
          <ChevronRightIcon className="h-3 w-4 stroke-2" />
        </button>
      )}

      {loadingProgress.progress > 0 && loadingProgress.progress < 100 && (
        <ProgressLoader loadingProgress={loadingProgress} />
      )}
    </div>
  );
};

export default LineSelect;
