import React from "react";
import FileItem from "./FileItem";
import Table from "../../../../common/Table";

const FilesList = ({ data }) => {
  const columns = [
    { title: "Name" },
    { title: "Tags" },
    { title: "Uploaded" },
    { title: "" },
  ];

  const renderRow = (item, rowIndex) => <FileItem key={item.id} item={item} />;

  return (
    <Table
      columns={columns}
      data={data}
      renderRow={renderRow}
      emptyMessage="No files yet"
    />
  );
};

export default FilesList;
