import React from "react";
import { PlusIcon, ShrinkSidebarIcon } from "../../../assets/icons";
import TooltipButton from "../../common/TooltipButton";
import { useNavigate } from "react-router-dom";

export const SidebarHeader = ({ groupCreateClick, setSizes }) => {
  const navigate = useNavigate();
  const currentProject = localStorage.getItem("activeProject");
  const currentProjectParsed = JSON.parse(currentProject);
  const projectTitle = currentProjectParsed?.title;

  if (!currentProject) {
    navigate("/");
  }

  const resizeEvent = new Event("resize");

  const handleResize = () => {
    setSizes([1]);
    window.dispatchEvent(resizeEvent);
  };

  return (
    <div
      className="flex items-center justify-between gap-2 border-b border-zinc-200 p-3 px-4 pr-3 h-12"
      style={{ minHeight: "48px" }}
    >
      <h1
        className="font-medium overflow-hidden text-nowrap whitespace-nowrap text-zinc-900"
        style={{ fontSize: "1rem" }}
      >
        {projectTitle}
      </h1>
      <div className="flex items-center">
        <TooltipButton
          onClick={groupCreateClick}
          icon={<PlusIcon className="w-5 h-5" />}
          tooltipText={"New group"}
        />

        <TooltipButton
          onClick={handleResize}
          icon={<ShrinkSidebarIcon />}
          tooltipText={"Hide"}
        />
      </div>
    </div>
  );
};
