import React from "react";
import { PencilIcon } from "../../../../assets/icons";
import Paragraph from "../../../common/ui/Paragraph";
import { hexToRgba } from "../../../../utils";

const GroupDetails = ({ group, handleOpenModal, setIsEditMode }) => {
  const style = {
    backgroundColor: hexToRgba(group.color, 0.2),
    outline: `solid 1.4px ${hexToRgba(group.color, 0.3)}`,
  };

  const handleClick = () => {
    setIsEditMode(true);
    handleOpenModal(true);
  };

  return (
    <div
      style={style}
      className="flex h-fit justify-between gap-2 relative rounded px-3 py-2"
    >
      <Paragraph
        size="p3"
        className="group-description"
        html={group.description}
      />
      <button
        className="group w-fit flex text-sm text-zinc-800 hover:text-zinc-900"
        role="menuitem"
        onClick={handleClick}
      >
        <PencilIcon
          className="h-4 w-4 text-zinc-800 group-hover:text-zinc-500"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

export default GroupDetails;
