import { useContext, useState } from "react";
import { NodeDataContext } from "../contexts";
import useCreateInversion from "./useCreateInversion";

const useUpdateInversion = ({ onInversionSuccess }) => {
  const { nodeData, activeNode, setActiveNode } = useContext(NodeDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const isTemplate = false;
  console.log(nodeData);

  const { createInversionMutation } = useCreateInversion(
    activeNode[0],
    activeNode[1],
    activeNode[2],
    activeNode[3],
    { id: nodeData.environment }, // WTF??!?
    { name: nodeData.title }, // WTF?!
    isTemplate,
    setActiveNode,
    onInversionSuccess,
    isLoading,
    setIsLoading,
    nodeData.inversion.id,
    nodeData.processing_version.id
  );

  const update = () => {
    createInversionMutation.mutate({
      className: nodeData.class_name,
      args: nodeData.arguments,
    });
  };

  return update;
};

export default useUpdateInversion;
