import React, { useState, useContext } from "react";

import {
  ArrowTopRightOnSquareIcon,
  ArrowLeftIcon,
  LayoutIcon,
  EllipsisVerticalIcon,
  ChevronUpDownIcon,
  CubeTransparentIcon,
  InversionProgressIcon,
  MapPinIcon,
} from "../../../../../assets/icons";
import { WorkspaceContext, NodeDataContext } from "../../../../../contexts";
import LineSelect from "./LineSelect";
import CustomizedModebar from "./CustomizedModebar";
import TooltipButton from "../../../../common/TooltipButton";
import { useSidebarContext } from "../../../../../contexts/SidebarContext";
import Tooltip from "../../../../common/Tooltip";
import ToolbarTooltipPanel from "./ToolbarTooltipPanel";
import { NodeInfo } from "./NodeInfo";
import { LayoutEditor } from "./LayoutEditor";
import SimpleSelect from "../../../../common/SimpleSelect";
import { UpdateInversionVersion } from "./UpdateVersion";
import { formatVersionTimestamp } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import { useActiveProject } from "../../../../../hooks/useActiveProject";

const VersionSelect = () => {
  const { nodeData, activeNode, setActiveNode } = useContext(NodeDataContext);

  if (nodeData.versions === undefined) return null;

  const items = nodeData.versions
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((item) => {
      const version = formatVersionTimestamp(item.created_at);
      return { ...item, title: version };
    });

  const nodeId = activeNode.slice(-1)[0];
  const versionId = nodeId.length ? nodeId[1] : undefined;
  const selected =
    versionId !== undefined
      ? items.filter((item) => item.id === versionId)[0]
      : undefined;

  return (
    <SimpleSelect
      items={items}
      selected={selected}
      setSelected={(item) => {
        console.log("Set version", item.id);
        let nodeId = activeNode.slice(-1)[0];
        if (nodeId.length) nodeId = nodeId[0];
        setActiveNode(activeNode.slice(0, -1).concat([[nodeId, item.id]]));
      }}
      width="w-fit"
      nothingTitle="Latest"
      buttonStyles={`relative flex items-center gap-2 justify-between w-fit rounded-md p-0.5 px-1.5
       border border-zinc-300 bg-zinc-50 text-zinc-700 hover:border-zinc-500`}
      IconComponent={ChevronUpDownIcon}
      iconPosition="after"
      dropdownWidth="w-48"
    />
  );
};

const Toolbar = ({
  subplotZooms,
  setSubplotZooms,
  setCurrentDragMode,
  progressView = false,
}) => {
  const [isManualEditSelect, setIsManualEditSelect] = useState(false);
  const { isToolbarExpanded, setIsToolbarExpanded } =
    useContext(WorkspaceContext);
  const { toggleSidebarResized } = useSidebarContext();

  const toggleToolbar = () => {
    setIsToolbarExpanded((prev) => !prev);
    toggleSidebarResized();
  };

  const openMap = () =>
    window.open("/views/components/workspace/modules/Map/Map");
  const openTab = () =>
    window.open("/views/components/workspace/modules/Plotting/default");
  const open3d = () =>
    window.open("/views/components/workspace/modules/View3d/View3d");

  const toolbarStyles = getToolbarStyles(isToolbarExpanded);
  const toggleText = isToolbarExpanded ? "Hide" : "Show";
  const toggleIcon = isToolbarExpanded ? (
    <div className="rotate-90 bg-zinc-800/20 p-1 rounded hover:bg-zinc-800/30">
      <ArrowLeftIcon className="w-3 h-3" />
    </div>
  ) : (
    <div className="-rotate-90 bg-zinc-800 hover:bg-zinc-700 p-1 rounded text-white">
      <ArrowLeftIcon className="w-3 h-3" />
    </div>
  );

  return (
    <div className={toolbarStyles.className}>
      {isToolbarExpanded && (
        <ToolbarExpandedContent
          setCurrentDragMode={setCurrentDragMode}
          subplotZooms={subplotZooms}
          setSubplotZooms={setSubplotZooms}
          setIsManualEditSelect={setIsManualEditSelect}
          isManualEditSelect={isManualEditSelect}
          openMap={openMap}
          openTab={openTab}
          open3d={open3d}
          progressView={progressView}
        />
      )}
      <div
        style={{ zIndex: 1000 }}
        className={`ml-auto ${isToolbarExpanded ? "" : "fixed right-1 mt-6"}`}
      >
        <Tooltip
          tooltipText={toggleText}
          placement={isToolbarExpanded ? "bottom" : "left"}
        >
          <button
            onClick={toggleToolbar}
            className={`tooltip-button hover:bg-transparent`}
            style={{ zIndex: 1000 }}
          >
            {toggleIcon}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const getToolbarStyles = (isExpanded) => ({
  className: `toolbar bg-white border-b border-zinc-200 ${
    isExpanded ? "h-fit min-h-12" : "h-1"
  } w-full flex gap-2 items-center px-4`,
});

const ToolbarExpandedContent = ({
  setCurrentDragMode,
  subplotZooms,
  setSubplotZooms,
  setIsManualEditSelect,
  isManualEditSelect,
  open3d,
  openMap,
  openTab,
  progressView,
}) => {
  const { activeNode, nodeData } = useContext(NodeDataContext);
  const onlyShowForInversion = activeNode?.length === 5;
  const { projectTitle } = useActiveProject();
  const navigate = useNavigate();

  const navigateToInversionProgress = () => {
    navigate(
      `/project/${projectTitle}/studio/processing-inversion/inversion-progress`
    );
  };

  return (
    <div className="toolbar-content flex items-center w-full gap-1 flex-wrap justify-between py-1.5 gap-y-1.5">
      <div className="flex items-center text-sm">
        <div className="flex gap-1 items-center text-sm">
          <div
            title={nodeData?.title}
            className="px-1 w-full overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {nodeData?.title}
          </div>

          <VersionSelect />

          {!progressView && onlyShowForInversion && <UpdateInversionVersion />}

          {onlyShowForInversion && (
            <TooltipButton
              onClick={navigateToInversionProgress}
              tooltipText="Inversion progress"
              tooltipSpacing={"3"}
              icon={<InversionProgressIcon />}
            />
          )}

          <ToolbarTooltipPanel
            content={<NodeInfo />}
            icon={<EllipsisVerticalIcon className="h-5 w-5" />}
            tooltipText={"More info"}
            width={"700px"}
            height={"600px"}
          />

          {!progressView && <LineSelect />}

          <div className="flex items-center">
            {!progressView && (
              <ToolbarTooltipPanel
                content={<LayoutEditor />}
                icon={<LayoutIcon />}
                tooltipText={"Workspace layouts"}
                width={"auto"}
              />
            )}
            {!progressView && (
              <TooltipButton
                onClick={openMap}
                tooltipText="Open map view"
                tooltipSpacing={"3"}
                icon={<MapPinIcon className="h-5 w-5 text-zinc-700" />}
              />
            )}
            {!progressView && onlyShowForInversion && (
              <TooltipButton
                onClick={open3d}
                tooltipText="Open 3d view"
                tooltipSpacing={"3"}
                icon={<CubeTransparentIcon className="h-5 w-5 text-zinc-700" />}
              />
            )}
          </div>
        </div>
      </div>

      {!progressView && (
        <div className="flex items-center">
          <CustomizedModebar
            subplotZooms={subplotZooms}
            setSubplotZooms={setSubplotZooms}
            setCurrentDragMode={setCurrentDragMode}
            setIsManualEditSelect={setIsManualEditSelect}
            isManualEditSelect={isManualEditSelect}
          />

          <div className="hidden">
            <TooltipButton
              onClick={openTab}
              tooltipText="Open in new tab"
              tooltipSpacing={"3"}
              icon={
                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-zinc-700" />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
