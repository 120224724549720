import {
  getGateTimes,
  titleDatasetChannel,
  schemaDatasetChannel,
  channel2col,
  channel2stdcol,
  channel2system,
} from "./utils";

const generic_sounding_gates_error_bars = (unitDipole) => {
  return {
    title: titleDatasetChannel,
    fn: function (context, args, elements) {
      const { nodeBinaryByLine, selectedLineId, selectedSoundingId } = context;
      const binary =
        nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary || !binary.flightlines) return [];
      const channel = channel2col(args.channel);
      const sounding = selectedSoundingId;
      const channelnr = parseInt(
        channel.replace(/[^0-9]*([0-9]*)[^0-9]*/, "$1")
      );
      const gatetimes = getGateTimes(binary, "Channel" + channelnr.toString());
      const systemChannel = channel2system(args.channel);
      const moment = unitDipole
        ? 1
        : binary.system[systemChannel].ApproxDipoleMoment;

      const stdChannel = channel2stdcol(args.channel);
      const dbdts = [];
      const stds = [];
      for (var col in binary.layer_data[channel]) {
        dbdts.push(Math.abs(binary.layer_data[channel][col][sounding]));
        stds.push(Math.abs(binary.layer_data[stdChannel][col][sounding]));
      }

      elements.yaxis[this.yaxis].exponentformat = "power";
      elements.xaxis[this.xaxis].exponentformat = "power";
      elements.yaxis[this.yaxis].tickfont = {
        size: 10,
      };
      elements.xaxis[this.xaxis].tickfont = {
        size: 10,
      };

      var xdist = binary.flightlines.xdist[sounding];

      const name = args.channel + " @ " + xdist?.toFixed(0) + "m";

      const createYMappingFunction = (side) => {
        return function (d, idx) {
          return side < 0 ? d * (1 + stds[idx]) : d / (1 + stds[idx]);
        };
      };
      const ys = dbdts.map((y) => y * 1e-12 * moment);
      const res = [];
      for (var side = -1; side < 2; side += 2) {
        res.push({
          type: "scatter",
          mode: "lines",
          fill: side > 0 ? "tonexty" : null,
          fillcolor: "rgba(128, 128, 128, 0.3)",
          line: { width: 1, color: "rgba(128, 128, 128, 0.3)" },
          legendgroup: "sounding_gates",
          name: name,
          showlegend: false,
          x: gatetimes,
          y: ys.map(createYMappingFunction(side)),
        });
      }
      return res;
    },
    xaxis: "gatetime",
    yaxis: unitDipole ? "dbdt" : "dbdtmoment",
    schema: schemaDatasetChannel,
  };
};

export const sounding_gates_error_bars =
  generic_sounding_gates_error_bars(true);
export const sounding_gates_error_bars_moment =
  generic_sounding_gates_error_bars(false);
