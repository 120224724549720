import React from "react";
import { ArrowRightEndOnRectangleIcon } from "../assets/icons";
import TooltipButton from "../components/common/TooltipButton";
import Projects from "../components/homepage/modules/Projects";
import Logo from "../assets/images/logo.png";

const HomePage = () => {
  return (
    <div className="bg-zinc-100 h-full">
      <div className="toolbar gradient-background border-b border-zinc-200 h-14 w-full flex gap-2 items-center px-4">
        <div className="flex gap-2.5 justify-between w-full mx-1">
        <img src={Logo} alt="EMerald iconmark" className="h-7 w-7.5" />
          <div className="flex gap-1 items-center justify-end">
            <TooltipButton
              to="/accounts/logout/"
              tooltipText={"Sign out"}
              icon={<ArrowRightEndOnRectangleIcon className="h-5 w-5" />}
              componentType="externalLink"
              additionalClasses="text-white"
            />
          </div>
        </div>
      </div>

      <Projects />
    </div>
  );
};

export default HomePage;
