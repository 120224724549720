import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CirclePicker } from "react-color";
import { NodeDataContext } from "../../../contexts";
import { colorOptions } from "../../../utils";
import Form, { FormInput, FormLabel } from "../../common/forms/Form";

// validation schema
const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
  description: yup.string().required("Description is required"),
});

const QuillModules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }], // lists
    ["link"], // link
  ],
};

const GroupForm = ({
  handleFormSubmit,
  onClose,
  submitButtonText,
  initialTitle,
  initialDescription,
  titleInputLabel,
  descriptionInputLabel,
}) => {
  const { nodeData } = useContext(NodeDataContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: initialTitle,
      description: initialDescription,
    },
  });

  const onSubmit = (data) => {
    data.color = selectedColor;
    handleFormSubmit(data);
  };

  const [selectedColor, setSelectedColor] = useState(
    nodeData.color || "#535357"
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="title"
        defaultValue={initialTitle || ""}
        render={({ field }) => (
          <FormInput
            label={titleInputLabel}
            placeholder="Title"
            error={errors.title}
            {...field}
          />
        )}
      />

      <ReactQuillField
        initialDescription={initialDescription}
        label={descriptionInputLabel}
        name="description"
        control={control}
        modules={QuillModules}
        error={errors.description}
      />

      <ColorPicker
        setSelectedColor={setSelectedColor}
        selectedColor={selectedColor}
      />

      <div className="button-group flex flex-row justify-end gap-2 mt-2">
        <button
          onClick={onClose}
          type="button"
          className="bg-transparent text-zinc-800 text-sm px-3 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-zinc-900 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-900/90 transition ease-in-out 2s"
        >
          {submitButtonText}
        </button>
      </div>
    </Form>
  );
};

const ColorPicker = ({ setSelectedColor, selectedColor }) => {
  return (
    <div className="flex flex-col gap-2 mb-2">
      <FormLabel label={"Pick a color"} htmlFor={"color"} />
      <input type="hidden" name="color" value={selectedColor} />
      <CirclePicker
        width="340px"
        colors={colorOptions}
        onChange={(color) => setSelectedColor(color.hex)}
      />
    </div>
  );
};

const ReactQuillWithRef = React.forwardRef((props, ref) => {
  return (
    <ReactQuill
      ref={ref}
      {...props}
      onChange={(content, delta, source, editor) => {
        props.onChange(content);
      }}
    />
  );
});

const ReactQuillField = ({
  initialDescription,
  label,
  control,
  name,
  modules,
  error,
}) => {
  return (
    <div className="flex flex-col">
      <FormLabel label={label} htmlFor={name} />
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <ReactQuillWithRef
            value={value || ""}
            onChange={onChange}
            modules={modules}
            placeholder="Type your description here..."
            theme="snow"
          />
        )}
      />
      {error && <p className="text-red-600 text-sm p-0.5">{error.message}</p>}
    </div>
  );
};

export default GroupForm;
