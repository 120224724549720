import React, { createContext, useContext, useMemo } from "react";
import { useInteractiveStates } from "../../../../contexts/InteractiveStateContext";
import { NodeDataContext } from "../../../../contexts";

// Do NOT use this outside of plotting!

export const PlotContext = createContext();

export const PlotContextProvider = ({ children }) => {
  const {
    nodeData,
    nodeModel,
    nodeType,
    workspaceLayout,
    nodeBinaryByLine,
    manualEdits,
    setManualEdits,
  } = useContext(NodeDataContext);

  const {
    selectedLineId,
    selectedSoundingId,
    setSelectedSoundingId,
    setSelectedSoundingColor,
    selectedSoundingColor,
  } = useInteractiveStates();

  const value = useMemo(
    () => ({
      nodeData,
      nodeModel,
      nodeType,
      workspaceLayout,
      selectedLineId,
      selectedSoundingId,
      setSelectedSoundingId,
      setSelectedSoundingColor,
      selectedSoundingColor,
      nodeBinaryByLine,
      manualEdits,
      setManualEdits,
    }),
    [
      nodeData,
      nodeModel,
      nodeType,
      workspaceLayout,
      selectedLineId,
      selectedSoundingId,
      setSelectedSoundingId,
      setSelectedSoundingColor,
      selectedSoundingColor,
      nodeBinaryByLine,
      manualEdits,
      setManualEdits,
    ]
  );

  return <PlotContext.Provider value={value}>{children}</PlotContext.Provider>;
};

export const usePlotContext = () => {
  return useContext(PlotContext);
};
