import FieldDescription from "./FieldDescription";
import { registerSchemaComponent } from "./fieldRegistration";
import { renderFormInputs } from "./renderFormInputs";

export const ObjectFormStep = ({
  value,
  propKey,
  schemaFragment,
  setValue,
  topLevelObject,
  ...props
}) => {
  // Render header and nested inputs

  const formContent = renderFormInputs({
    ...props,
    properties: schemaFragment.properties,
    value: value || {}, // Ensure nested data exists
    setValue: setValue,
  });

  if (topLevelObject) {
    return formContent;
  }

  return (
    <>
      <div className="flex items-center mb-2 mt-2">
        <h4 className="font-medium text-sm capitalize">{propKey}</h4>
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
      <div className={`object_step ${props.formStyle}`}>{formContent}</div>
    </>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    (schemaFragment?.type === "object" || schemaFragment?.type === undefined) &&
    schemaFragment?.properties
  );
}, ObjectFormStep);
