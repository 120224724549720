import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "./TextField";
import { schema } from "./validation";
import useCreateEnvironment from "../../../../../hooks/useCreateEnvironment";
import { DisplayErrorMsg } from "../../../../common/DisplayErrorMsg";
import { capitalizeFirstLetter } from "../../../../../utils";
import { useEnvironment } from "../../../../../hooks/api/useEnvironments";
import jsyaml from "js-yaml";
import Form, { FormInput } from "../../../../common/forms/Form";
import Spinner from "../../../../common/ui/Spinner";

const EnvironmentForm = ({ setShowForm, environmentBase }) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let { data: defaultValues } = useEnvironment(environmentBase);
  if (defaultValues !== undefined) {
    defaultValues = { ...defaultValues };
    defaultValues.specification = jsyaml.dump(defaultValues.specification);
  }

  const { submitEnvironment } = useCreateEnvironment(
    setShowForm,
    setIsLoading,
    setErrorMsg
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const newData = {
        title: capitalizeFirstLetter(data.title),
        specification: jsyaml.load(data.specification),
      };
      submitEnvironment(newData);
    } catch (error) {
      setErrorMsg(
        `Sorry, an error occured: ${error.response.status} ${error.response.statusText}`
      );
      console.error("File Upload Error:", error);
    }
  };

  const onError = (errors, e) => console.error("errors", errors, "event", e);

  return (
    <div className="p-4">
      {errorMsg && (
        <div className="mx-4 mt-4">
          <DisplayErrorMsg message={errorMsg} />
        </div>
      )}

      <Form
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <FormInput
              label={"Title"}
              htmlFor={"title"}
              placeholder="Write a title for the environment"
              error={errors.title}
              defaultValue={defaultValues?.title}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="specification"
          render={({ field }) => (
            <TextField
              label={"Specification"}
              htmlFor={"specification"}
              cols="200"
              rows="10"
              placeholder="Specification"
              error={errors.specification}
              defaultValue={defaultValues?.specification}
              {...field}
            />
          )}
        />

        <div className="button-group flex flex-row justify-between mt-2">
          <button
            onClick={() => setShowForm(false)}
            type="button"
            className="bg-transparent text-zinc-600 text-sm px-4 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-zinc-600 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-700 flex items-center"
          >
            {isLoading && (
              <Spinner
                borderColor="rgba(255, 255, 255, 0.5)"
                borderTopColor="white"
              />
            )}
            Create
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EnvironmentForm;
