import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TagsField } from "./TagsField";
import { NodeDataContext } from "../../../../../contexts";
import { fetchTags } from "../../../../../api/fetchTags";
import { fetchDatatypes } from "../../../../../api/fetchDatatypes";
import { DataTypeSelect } from "./DataTypeSelect";
import { schema } from "./validation";
import useCreateTag from "../../../../../hooks/useCreateTag";
import { importFile } from "../../../../../api/importFile";
import useCreateAuxData from "../../../../../hooks/useCreateAuxData";
import { DisplayErrorMsg } from "../../../../common/DisplayErrorMsg";
import { capitalizeFirstLetter } from "../../../../../utils";
import Form, { FormInput, FileInput } from "../../../../common/forms/Form";
import Spinner from "../../../../common/ui/Spinner";

/*

- Make common input 
- Refactor the form 
- Fix uncontrolled / controllet title input ??

*/

const MiscDataForm = ({ setShowForm }) => {
  const { activeNode } = useContext(NodeDataContext);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const projectId = activeNode[0];

  const [tagsExamples, setTagsExamples] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [datatypes, setDatatypes] = useState([]);
  const [selectedDatatype, setSelectedDatatype] = useState([]);

  const { submitNewTags } = useCreateTag(tagsExamples, projectId, setErrorMsg);
  const { submitAuxiliaryData } = useCreateAuxData(
    projectId,
    setShowForm,
    setIsLoading,
    setErrorMsg
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchTagsAndDatatypes = async () => {
    try {
      const tags = await fetchTags(projectId);
      if (tags) {
        setTagsExamples(tags);
      }

      const dTypes = await fetchDatatypes();
      if (dTypes) {
        setDatatypes(dTypes);
        setSelectedDatatype(dTypes[0]);
        setValue("datatype", dTypes[0]);
      }
    } catch (error) {
      setErrorMsg(`Failed to fetch tags and datatypes: ${error.message}`);
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchTagsAndDatatypes();
  }, [projectId, setValue]); // eslint-disable-line

  const onSubmit = async (data) => {
    setIsLoading(true);

    if (data.file && data.file[0]) {
      try {
        // Step 1: Upload the file and receive a response.
        const fileResponse = await importFile(data.file[0]);

        // Ensure the file was uploaded successfully and we have a file path.
        if (fileResponse.data && fileResponse.data.file) {
          // Step 2: Construct new data payload.
          const tagIds = data.tags.map((tag) => tag.id);

          const newData = {
            ...data,
            src: fileResponse.data.file,
            project: projectId,
            datatype_id: parseInt(data.datatype.id),
            tags_ids: tagIds,
            title: capitalizeFirstLetter(data.title),
          };

          delete newData.file;
          delete newData.datatype;

          // Step 3a: Submit the tags using modified data.
          await submitNewTags(newData);

          // Step 3b: Delete tags array of objects
          delete newData.tags;

          // Step 4: Submit the auxiliary data using modified data.
          submitAuxiliaryData(newData);
        } else {
          setErrorMsg(`File upload succeeded but no file path received`);
          console.error("File upload succeeded but no file path received");
        }
      } catch (error) {
        setErrorMsg(
          `Sorry, an error occured: ${error.response.status} ${error.response.statusText}`
        );
        console.error("File Upload Error:", error);
      }
    } else {
      setErrorMsg(`No file is provided for the upload.`);
      console.error("No file provided for upload");
    }
  };

  const onError = (errors, e) => console.error("errors", errors, "event", e);

  return (
    <div className="p-4">
      {errorMsg && (
        <div className="mb-2">
          <DisplayErrorMsg message={errorMsg} />
        </div>
      )}

      <Form
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        {selectedDatatype.title === "Geometries" && (
          <div className="text-sm text-zinc-600 py-1.5 px-2 rounded-md bg-blue-50 border border-l-4 border-blue-300">
            Shapefiles needs to be uploaded in a .zip file.
          </div>
        )}

        <Controller
          control={control}
          name="file"
          render={({ field }) => (
            <FileInput
              onChange={(e) => field.onChange(e.target.files)}
              error={errors.file}
              ref={field.ref}
            />
          )}
        />

        <Controller
          control={control}
          name="title"
          defaultValue=""
          render={({ field }) => (
            <FormInput
              label="Title"
              htmlFor="title"
              placeholder="Write a title for the upload"
              error={errors.title}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="datatype"
          render={({ field }) => (
            <DataTypeSelect
              datatypes={datatypes}
              selectedDatatype={selectedDatatype}
              setSelectedDataype={setSelectedDatatype}
              error={errors.dataType}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="tags"
          defaultValue={selectedTags}
          render={({ field }) => (
            <TagsField
              label={"Add tags"}
              examples={tagsExamples}
              error={errors.tags}
              handleTagsChange={(newTags) => {
                setSelectedTags(newTags);
                field.onChange(newTags);
              }}
              tags={selectedTags}
            />
          )}
        />

        <div className="button-group flex flex-row justify-between mt-2">
          <button
            onClick={() => setShowForm(false)}
            type="button"
            className="bg-transparent text-zinc-600 text-sm px-4 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-zinc-600 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-700 flex items-center"
          >
            {isLoading && (
              <Spinner
                borderColor="rgba(255, 255, 255, 0.5)"
                borderTopColor="white"
              />
            )}
            Upload
          </button>
        </div>
      </Form>
    </div>
  );
};

export default MiscDataForm;
