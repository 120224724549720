import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "../../../assets/icons";
import { SidebarHeader } from "./SidebarHeader";
import GroupList from "../../common/NavTree/GroupList";
import { ErrorHandler } from "../../common/ErrorHandler";

import { NodeDataContext } from "../../../contexts";

import NavTree from "../../common/NavTree";
import ElementDetailsContainer from "./ElementDetails/ElementDetailsContainer";
import Tooltip from "../../common/Tooltip";
import { useActiveProject } from "../../../hooks/useActiveProject";
import ActionButtons from "./ActionButtons";
import EditDialog from "./EditDialog";
import { SidebarContext } from "../../../contexts/SidebarContext";

const Sidebar = ({ setSizes }) => {
  // Hooks and context
  const navigate = useNavigate();

  const { activeNode, setActiveNode } = useContext(NodeDataContext);
  const { setNodeItemClicked } = useContext(SidebarContext);
  const { projectTitle } = useActiveProject();
  const [contentVisible, setContentVisible] = useState(true);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (!projectTitle) {
      navigate("/");
    }
  }, [projectTitle, navigate]);

  const handleNodeClick = (path, action) => {
    setActiveNode(path);

    if (path.length === 2) {
      if (action === undefined) {
        navigate(`/project/${projectTitle}/studio/processing-inversion/map`);
      } else {
        navigate(
          `/project/${projectTitle}/studio/processing-inversion/new-import`
        );
      }
    } else if (path.length === 3) {
      if (action === undefined) {
        navigate(`/project/${projectTitle}/studio/processing-inversion`);
      } else {
        navigate(
          `/project/${projectTitle}/studio/processing-inversion/new-processing`
        );
      }
    } else if (path.length === 4) {
      if (action === undefined) {
        navigate(`/project/${projectTitle}/studio/processing-inversion`);
      } else {
        navigate(
          `/project/${projectTitle}/studio/processing-inversion/new-inversion`
        );
      }
    } else if (path.length === 5) {
      if (action === undefined) {
        navigate(`/project/${projectTitle}/studio/processing-inversion`);
      }
    }
  };

  useEffect(() => {
    const handleResize = (entries) => {
      for (let entry of entries) {
        // Check the sidebar's width
        setContentVisible(entry.contentRect.width > 100);
      }
    };

    const observer = new ResizeObserver(handleResize);

    if (sidebarRef.current) {
      observer.observe(sidebarRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const [isEditMode, setIsEditMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = () => setIsOpen(true);

  const handleClosePopup = () => {
    setIsOpen(false);
    setIsEditMode(true);
  };

  const groupCreateClick = () => {
    setNodeItemClicked({ data_type: "group" });
    setIsEditMode(false);
    handleOpenPopup(true);
  };

  // Render
  return (
    <div
      ref={sidebarRef}
      className={`sidebar bg-white text-sm flex flex-col rounded-tl-lg rounded-bl-lg
      h-screen min-h-screen border-r border-zinc-100 transition-all duration-300 ease-in-out`}
    >
      <ErrorHandler>
        {contentVisible && (
          <SidebarHeader
            groupCreateClick={groupCreateClick}
            setSizes={setSizes}
          />
        )}
      </ErrorHandler>
      {contentVisible && (
        <ErrorHandler>
          <div
            style={{ height: "inherit", minHeight: "fit-content" }}
            className="sidebar__main px-3 pl-4 py-2 relative overflow-hidden overflow-y-scroll"
          >
            <div className="nav-tree__header flex justify-between items-center mb-2 hidden">
              <div className="text-xs font-medium text-zinc-500 uppercase">
                Data groups
              </div>
              <Tooltip tooltipText="New group" placement="left">
                <button
                  onClick={groupCreateClick}
                  className="hover:bg-zinc-200 rounded text-zinc-600 hover:text-zinc-700 hover:scale-105 transition ease-in-out"
                >
                  <PlusIcon className="w-5 h-5" />
                </button>
              </Tooltip>
            </div>
            <NavTree>
              <GroupList
                activePath={activeNode}
                onNodeClick={handleNodeClick}
                openEditDialog={handleOpenPopup}
                setIsEditMode={setIsEditMode}
                ActionButtons={ActionButtons}
              />
            </NavTree>
          </div>

          <ElementDetailsContainer
            handleOpenModal={handleOpenPopup}
            setIsEditMode={setIsEditMode}
          />
        </ErrorHandler>
      )}

      <EditDialog
        isOpen={isOpen}
        isEditMode={isEditMode}
        closePopup={handleClosePopup}
      />
    </div>
  );
};

export default Sidebar;
