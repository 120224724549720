import React, { useContext, useEffect, useRef } from "react";
import { NodeDataContext } from "../../../../contexts";
import { InteractiveStateContext } from "../../../../contexts/InteractiveStateContext";
import { elements } from "./PlotElements";

import { usePlotContext } from "./PlotContext";

import { BasePlot } from "emerald-plotly-react";
import {
  useWorkspaceLayout,
  useWorkspaceLayouts,
} from "../../../../hooks/api/useWorkspaceLayouts";
import LoadingScreen from "../../../common/ui/LoadingScreen";

const EditableElementPlotWrapper = ({
  subplotZooms,
  setSubplotZooms,
  currentDragMode,
}) => {
  const {
    nodeBinaryByLine,
    workspaceLayout,
    setWorkspaceLayout,
    selections,
    setSelections,
    nodeType,
  } = useContext(NodeDataContext);

  const { selectedLineId } = useContext(InteractiveStateContext);
  const plotContext = usePlotContext();
  const plotRef = useRef(null);

  const { data: layouts } = useWorkspaceLayouts();

  const datasetLayoutId = layouts?.find(
    (layout) =>
      layout.title === "Dataset plot" &&
      layout.dimensions === "3x2" &&
      layout.node_type.includes("dataset")
  )?.id;

  const processingLayoutId = layouts?.find(
    (layout) =>
      layout.title === "Dual channel data plot" &&
      layout.dimensions === "2x2" &&
      layout.node_type.includes("processing")
  )?.id;

  const inversionLayoutId = layouts?.find(
    (layout) => layout.title === "Inversion plot"
  )?.id;

  const { data: datasetLayout } = useWorkspaceLayout(datasetLayoutId);
  const { data: inversionLayout } = useWorkspaceLayout(inversionLayoutId);
  const { data: processingLayout } = useWorkspaceLayout(processingLayoutId);

  useEffect(() => {
    if (datasetLayout && inversionLayout && processingLayout) {
      setWorkspaceLayout({
        dataset: datasetLayout,
        processing: processingLayout,
        inversion: inversionLayout,
      });
    }
  }, [datasetLayout, inversionLayout, processingLayout, setWorkspaceLayout]);

  let plot =
    workspaceLayout &&
    workspaceLayout[nodeType] &&
    workspaceLayout[nodeType].specification
      ? JSON.parse(JSON.stringify(workspaceLayout[nodeType].specification))
      : null;

  const setPlot = (plot) => {
    const layout = JSON.parse(JSON.stringify(workspaceLayout));
    if (!layout[nodeType]) {
      layout[nodeType] = {};
    }

    layout[nodeType].specification = plot;
    setWorkspaceLayout(layout);
  };

  const plotData = nodeBinaryByLine && selectedLineId ? plot : null;

  return (
    <div
      ref={plotRef}
      className="h-[100%] w-[100%] p-0.5 rounded-md bg-white w-full h-full relative shadow-sm shadow-zinc-200"
    >
      <div className="h-[100%] w-[98%]">
        <BasePlot
          context={plotContext}
          plot={plotData}
          setPlot={setPlot}
          elements={elements}
          subplotZooms={subplotZooms}
          setSubplotZooms={setSubplotZooms}
          currentDragMode={currentDragMode}
          selections={selections}
          setSelections={setSelections}
          useDefaultModebar={false}
        >
          <LoadingScreen />
        </BasePlot>
      </div>
    </div>
  );
};

export default EditableElementPlotWrapper;
