import { useSafeQuery } from "./useSafeQuery";
import { fetchBinary } from "../../api/fetchBinary";
import client from "../../api/client";

// Fetch and cache the datasets data for a specific group

export const fetchDatasets = async (projectId, groupId) => {
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports`
  );
  return data;
};

export const fetchDatasetDetails = async (projectId, groupId, datasetId) => {
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}`
  );
  return data;
};

export const fetchDatasetBinary = async (
  projectId,
  groupId,
  datasetId,
  selectedLineId,
  onProgress
) => {
  const url = `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/measured/${selectedLineId}/binary`;
  return {
    measured: await fetchBinary(url, onProgress),
  };
};

export const fetchDatasetProgress = async (projectId, groupId, datasetId) => {
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/progress`
  );
  return data;
};

export function useDatasets(projectId, groupId) {
  return useSafeQuery(["datasets", projectId, groupId], () =>
    fetchDatasets(projectId, groupId)
  );
}
