import { useSafeQuery } from "./useSafeQuery";
import { fetchProjectDetails } from "./useProjects";
import { fetchGroupDetails } from "./useGroups";
import { fetchDatasetBinary, fetchDatasetDetails } from "./useDatasets";
import {
  fetchProcessingBinary,
  fetchProcessingDetails,
} from "./useProcessings";
import { fetchInversionBinary, fetchInversionDetails } from "./useInversions";
import { useQueryClient } from "@tanstack/react-query";
import { useInteractiveStates } from "../../contexts/InteractiveStateContext";

// Fetch and cache node details and binary data

export const fetchNodeDetails = (activeNode) => {
  const fetchFunctions = [
    fetchProjectDetails,
    fetchGroupDetails,
    fetchDatasetDetails,
    fetchProcessingDetails,
    fetchInversionDetails,
  ];

  const fetchFunction = fetchFunctions[activeNode.length - 1];
  return fetchFunction ? fetchFunction(...activeNode) : null;
};

export const fetchNodeBinary = (
  queryClient,
  activeNode,
  nodeData,
  selectedLineId
) => {
  const fetchFunctions = [
    null,
    null,
    (...args) => fetchDatasetBinary(...args, selectedLineId),
    (...args) => fetchProcessingBinary(queryClient, ...args, selectedLineId),
    (...args) => fetchInversionBinary(...args, selectedLineId),
  ];
  const fetchFunction = fetchFunctions[activeNode.length - 1];
  return fetchFunction ? fetchFunction(...activeNode, nodeData) : null;
};

export const useNodeDetailsQuery = (activeNode) => {
  return useSafeQuery(["nodeData", activeNode], () => {
    return fetchNodeDetails(activeNode);
  });
};

export const useNodeBinaryQuery = (activeNode, nodeData) => {
  const { selectedLineId } = useInteractiveStates();
  const queryClient = useQueryClient();
  const isDone = activeNode.length > 2 && nodeData?.status === "done";
  return useSafeQuery({
    queryKey: [
      "nodeBinary",
      ...activeNode,
      nodeData?.id,
      selectedLineId,
      isDone,
    ],
    queryFn: () => {
      if (!isDone || !selectedLineId) return null;
      return fetchNodeBinary(queryClient, activeNode, nodeData, selectedLineId);
    },
    gcTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: Infinity,
  });
};
