import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ArrowDownTrayIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "../../../../../assets/icons";
import { useAddStyleClick } from "../../../../../hooks/mapHooks/useAddRasterStyle";
import useDeleteListItem from "../../../../../hooks/useDeleteListItem";

const FileItemMenu = ({ src, openModal, item, setStyleLoading }) => {
  const onAddStyleClick = useAddStyleClick("allAuxiliaryData");
  const { handleDelete } = useDeleteListItem();
  const menuItemsRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const adjustPosition = () => {
    const menuItems = menuItemsRef.current;
    console.log("menuItems on click", menuItems);
    if (!menuItems) return;

    const rect = menuItems.getBoundingClientRect();
    const { innerHeight } = window;

    let newTop = 0; // initial top position

    // Check for bottom overflow
    if (rect.bottom > innerHeight) {
      newTop = innerHeight - rect.height - rect.top - 10;
      console.log("Menu.Items overflows the bottom of the viewport");
    }

    // Apply new position
    menuItems.style.top = `${newTop}px`;
  };

  useEffect(() => {
    if (menuOpen) {
      adjustPosition();
      window.addEventListener("resize", adjustPosition);
    } else {
      window.removeEventListener("resize", adjustPosition);
    }
    return () => {
      window.removeEventListener("resize", adjustPosition);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuItemsRef.current) {
      adjustPosition();
    }
  }, [menuItemsRef]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className="inline-flex w-full"
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <EllipsisVerticalIcon className="w-5 h-5" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterEnter={adjustPosition} // Adjust position after enter transition completes
      >
        <Menu.Items
          ref={menuItemsRef}
          className="fileitemmenu absolute left-6 top-0 w-40 origin-top-right divide-y divide-zinc-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {item.url && (
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      onAddStyleClick(item.project, item.id);
                      setStyleLoading(true);
                    }}
                    className={`${
                      active ? "bg-zinc-100 text-zinc-600" : "text-zinc-900"
                    } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                  >
                    <PlusIcon aria-hidden="true" className="w-5 h-5" />
                    Add style
                  </button>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={openModal}
                  className={`${
                    active ? "bg-zinc-100 text-zinc-600" : "text-zinc-900"
                  } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                >
                  <PencilIcon aria-hidden="true" className="w-5 h-5" />
                  Edit
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <a
                  download
                  href={src}
                  className={`${
                    active ? "bg-zinc-100 text-zinc-600" : "text-zinc-900"
                  } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                >
                  <ArrowDownTrayIcon aria-hidden="true" className="w-5 h-5" />
                  Download
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleDelete(item.id, item.title, "auxdata")}
                  className={`${
                    active ? "bg-zinc-100 text-zinc-600" : "text-zinc-900"
                  } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                >
                  <TrashIcon aria-hidden="true" className="w-5 h-5" />
                  Delete
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default FileItemMenu;
